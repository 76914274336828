import _ from 'lodash'
import { FIELDS, FIELDS_ROLES } from '../../../constants/roles'
import { getLayout } from './contact-form-layout'

export const enhanceFormWithMobileStructure = (
  contactForm: DynamicContactForm,
  convertedForm: RawComponentStructure,
  { mobileLayouts: layouts },
): RawComponentStructure => {
  const contactFormMobileLayout = _.get(contactForm.mobileStructure, 'layout')
  if (!contactFormMobileLayout) {
    return convertedForm
  }
  return {
    ...convertedForm,
    components: convertedForm.components.map((component, index) => {
      if (component.role === FIELDS.ROLE_FIELD_COMPLEX_PHONE_WIDGET) {
        return getComplexPhoneLayout(contactForm, index, layouts, component)
      } else {
        const inputLayout = calcComponentMobileLayout(contactForm, layouts[index], component)
        return _.includes(FIELDS_ROLES, component.role)
          ? inputLayout
          : { ...component, mobileStructure: { layout: inputLayout.mobileStructure.layout } }
      }
    }),
    mobileStructure: { layout: contactFormMobileLayout },
  }
}

export const calcComponentMobileLayout = (
  contactForm: DynamicContactForm,
  compLayout,
  component: Partial<RawComponentStructure>,
) => {
  const { layout, inputHeight } = getLayout(
    { skin: contactForm.skin, contactFormWidth: contactForm.mobileStructure.layout.width },
    component,
    compLayout,
  )
  return {
    ...component,
    ...(component.props
      ? {
          props: {
            ...component.props,
            inputHeightMobile: inputHeight,
          },
        }
      : {}),
    mobileStructure: {
      layout,
    },
  }
}

const getComplexPhoneLayout = (
  contactForm: DynamicContactForm,
  index: number,
  layouts,
  component: Partial<RawComponentStructure>,
) => {
  const widget = component
  const [container] = component.components
  const [dropdown, text] = container.components

  const [widgetOriginalLayout, dropdownOriginalLayout, textOriginalLayout] = layouts[index] || []

  const widgetLayout = calcComponentMobileLayout(contactForm, widgetOriginalLayout, widget)
    .mobileStructure.layout
  const containerLayout = calcComponentMobileLayout(contactForm, widgetOriginalLayout, container)
    .mobileStructure.layout

  const dropdownWithLayout = calcComponentMobileLayout(
    contactForm,
    dropdownOriginalLayout,
    dropdown,
  )
  const textWithLayout = calcComponentMobileLayout(contactForm, textOriginalLayout, text)

  return {
    ...component,
    mobileStructure: { layout: widgetLayout },
    components: [
      {
        ...container,
        mobileStructure: { layout: containerLayout },
        components: [dropdownWithLayout, textWithLayout],
      },
    ],
  }
}
