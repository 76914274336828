import { FormPlugin, FormsFieldPreset } from '@wix/forms-common'
import { COMPLEX_PHONE_ROLES } from '../../../../../../../constants/roles'
import { Phone } from '../../../recommended-fields/definitions/phone'
import { DEFAULT_PHONE_NUMBER_VALIDATION_REGEX } from '../constants'

export class ComplexPhoneText extends Phone {
  public get fieldType(): FieldPreset {
    return FormsFieldPreset.COMPLEX_PHONE_TEXT
  }

  public get label() {
    return this.translate('complexPhone.textInput.defaultTitle')
  }

  public get role() {
    return COMPLEX_PHONE_ROLES.TEXT
  }

  public get subRole() {
    return null
  }

  public get disableCollectionSync() {
    return true
  }

  protected get layout() {
    return {
      x: 128,
      y: 0,
      width: 240,
      height: 42,
    }
  }

  protected get data(): any {
    return {
      pattern: DEFAULT_PHONE_NUMBER_VALIDATION_REGEX,
      textType: 'tel',
      placeholder: this.translate(`fieldTypes.${this.fieldType}`),
    }
  }

  protected get mobileHints() {
    return {
      recommendedWidth: 165,
      offsetX: 95,
      offsetY: 0,
      offsetOrigin: 'leftTop',
    }
  }

  protected get renderConfig() {
    return {
      [FormPlugin.FORM_BUILDER]: {
        layout: {
          row: 0,
          col: 1,
          width: 61,
        },
        addFieldPanelData: null,
      },
    }
  }
}
