import {
  ResponsiveLayout,
  SimpleSize,
  StackItemLayout,
  GridItemPosition,
  GridLayout,
  ComponentDefinition,
} from '@wix/platform-editor-sdk'

import _ from 'lodash'
import {
  FIELDS_ROLES_TO_APPEAR_BEFORE_USER_NEW_FIELD,
  ROLE_SUBMIT_BUTTON,
  ROLE_TITLE,
} from '../../../constants/roles'

const metaData = {
  isPreset: false,
  schemaVersion: '1.0',
  isHidden: false,
}

const getItemLayoutType = (containerLayout): ResponsiveItemLayoutType => {
  const containerLayoutType: ResponsiveContainerLayoutType = getContainerType(containerLayout)
  switch (containerLayoutType) {
    case 'GridContainerLayout':
      return 'GridItemLayout'
    default:
      return 'StackItemLayout'
  }
}

const componentsLayout = (height): ResponsiveLayout['componentLayouts'] => [
  {
    type: 'ComponentLayout',
    width: {
      type: 'percentage',
      value: 90,
    },
    height: height
      ? {
          type: 'px',
          value: height,
        }
      : { type: 'auto' },
    hidden: false,
    breakpoint: undefined,
  },
]

const createItemLayout = ({
  itemLayoutType,
  height,
  layoutData,
}: {
  itemLayoutType: ResponsiveItemLayoutType
  height?: SimpleSize | number
  layoutData?: ResponsiveLayoutData
}): ResponsiveLayout => {
  const { order, gridArea } = layoutData || {}

  switch (itemLayoutType) {
    case 'GridItemLayout':
      return createGridItemLayout({ height, gridArea })
    default:
      return createStackItemLayout({ height, order })
  }
}

const fieldLayouts = (fieldsData: FormField[]): AnyItemLayout[] =>
  fieldsData
    .filter((fieldData) => _.includes(FIELDS_ROLES_TO_APPEAR_BEFORE_USER_NEW_FIELD, fieldData.role))
    .map((fieldData) => fieldData.layoutResponsive.itemLayouts[0])

const getItemLayoutData = (
  layoutResponsive: ResponsiveLayout,
  incrementPosition?: boolean,
): ResponsiveLayoutData => {
  const itemLayout = _.get(layoutResponsive, 'itemLayouts[0]')

  if (!itemLayout) {
    return undefined
  }

  switch (itemLayout.type) {
    case 'GridItemLayout':
      return { gridArea: itemLayout.gridArea }
    default:
      const order = (itemLayout as StackItemLayout).order
      return { order: incrementPosition ? order + 1 : order }
  }
}

const getComponentLayout = (field): ComponentLayout =>
  _.get(field, 'layoutResponsive.componentLayouts[0]')

const getItemLayout = (field): AnyItemLayout => _.get(field, 'layoutResponsive.itemLayouts[0]')

const getContainerType = (layoutResponsive: ResponsiveLayout): ResponsiveContainerLayoutType =>
  _.get(layoutResponsive, 'containerLayouts[0].type')

// Stack Utils
const findNewFieldStackOrder = (
  fieldsData: FormField[],
): { order: ResponsiveLayoutData['order'] } => {
  const fieldsLayout = fieldLayouts(fieldsData) as StackItemLayout[]

  const lastFieldLayout: StackItemLayout = _.maxBy(fieldsLayout, 'order')

  return { order: _.get(lastFieldLayout, 'order', 0) + 1 }
}

const calcUpdatesForStackFieldsByNewOrder = (
  responsiveLayouts: {
    componentRef: ComponentRef
    layoutResponsive: ResponsiveLayout
    role?: string
  }[],
  newFieldOrder: number,
): { componentRef: ComponentRef; responsiveLayout: ResponsiveLayout }[] => {
  const fieldsNeededForUpdate = responsiveLayouts.filter(
    (fieldData) => getOrderFromResponsiveLayout(fieldData.layoutResponsive) >= newFieldOrder,
  )

  return fieldsNeededForUpdate.map((fieldData) => {
    const defaultStackItemLayout = fieldData.layoutResponsive.itemLayouts[0] as StackItemLayout
    return {
      componentRef: fieldData.componentRef,
      responsiveLayout: {
        ...fieldData.layoutResponsive,
        itemLayouts: [{ ...defaultStackItemLayout, order: defaultStackItemLayout.order + 1 }],
      },
    }
  })
}

const getOrderFromResponsiveLayout = (layoutResponsive: ResponsiveLayout): number =>
  (layoutResponsive.itemLayouts[0] as StackItemLayout).order

const createStackItemLayout = ({
  order,
  height,
}: {
  order?: number
  height?: SimpleSize | number
}): ResponsiveLayout => ({
  id: '',
  type: 'LayoutData',
  metaData,
  containerLayouts: [],
  componentLayouts: componentsLayout(height),
  itemLayouts: [
    {
      type: 'StackItemLayout',
      alignSelf: 'center',
      justifySelf: 'center',
      order: order || 1,
      margins: {
        left: {
          type: 'px',
          value: 20,
        },
        top: {
          type: 'px',
          value: 20,
        },
      },
      breakpoint: undefined,
    },
  ],
})

// Grid Utils
export interface FieldWithGridLayout {
  gridLayout: GridItemPosition
  role: string
  fieldType: FieldPreset
}

export interface FieldWithGridLayouts {
  gridLayouts: GridItemPosition[]
  role: string
  fieldType: FieldPreset
}

const createGridItemLayout = ({
  gridArea,
  height,
}: {
  gridArea?: ResponsiveLayoutData['gridArea']
  height?: SimpleSize | number
  incrementPosition?: boolean
}): ResponsiveLayout => {
  return {
    id: '',
    type: 'LayoutData',
    metaData,
    containerLayouts: [],
    componentLayouts: componentsLayout(height),
    itemLayouts: [
      {
        type: 'GridItemLayout',
        alignSelf: 'center',
        justifySelf: 'center',
        gridArea: gridArea || {
          rowStart: 1,
          rowEnd: 2,
          columnStart: 1,
          columnEnd: 2,
        },
        breakpoint: undefined,
      },
    ],
  }
}

const getContainerGridSize = (layoutResponsive: GridLayout): GridSize => {
  return {
    rows: layoutResponsive.rows?.length || 0,
    cols: layoutResponsive.columns?.length || 0,
  }
}

const getRowSize = (layoutResponsive: GridLayout) => layoutResponsive.rows[0]

const getItemGridSize = (gridItemPosition: GridItemPosition): GridSize => {
  const itemGridArea = gridItemPosition.gridArea
  return {
    rows: itemGridArea.rowEnd - itemGridArea.rowStart,
    cols: itemGridArea.columnEnd - itemGridArea.columnStart,
  }
}

const fieldGridSize = (rows = 1, cols = 1): GridSize => ({ rows, cols })

const findEmptyCellInGrid = (
  containerGridSize: GridSize,
  allFieldsData: FieldWithGridLayout[],
  cellSize: GridSize,
): EmptyCellResponse => {
  const searchLimit = _getSearchLimits(allFieldsData, containerGridSize)
  for (let r = 1; r <= containerGridSize.rows; r++) {
    if (r <= searchLimit.top) {
      continue
    }
    if (r >= searchLimit.bottom) {
      break
    }

    const index = _getFreeCellLocationInRow({
      allFieldsData,
      gridSize: containerGridSize,
      cellSize,
      row: r,
    })
    if (index > 0) {
      return {
        found: true,
        gridArea: {
          rowStart: r,
          rowEnd: r + cellSize.rows,
          columnStart: index,
          columnEnd: index + cellSize.cols,
        },
      }
    }
  }

  return { found: false, lastIndex: searchLimit.bottom - 1 }
}

const _getSearchLimits = (
  allFieldsData: FieldWithGridLayout[],
  containerGridSize,
): { top: number; bottom: number } => {
  // by design, submit button is the main limit
  const title = _getRowNumberByRole(allFieldsData, ROLE_TITLE)
  const submit =
    _getRowNumberByRole(allFieldsData, ROLE_SUBMIT_BUTTON) || containerGridSize.rows + 1
  return {
    top: submit > title ? title : -1,
    bottom: submit,
  }
}

const _getRowNumberByRole = (allFieldsData: FieldWithGridLayout[], role: string): number =>
  allFieldsData.filter((f) => f.role === role).map((f) => f.gridLayout.gridArea.rowStart)[0]

// TODO : will check how to do it differently later
const _getFreeCellLocationInRow = ({
  allFieldsData,
  gridSize,
  cellSize,
  row,
}: {
  allFieldsData: FieldWithGridLayout[]
  gridSize: GridSize
  cellSize: GridSize
  row: number
}): number => {
  const allFieldsInRow = allFieldsData.filter(
    ({ gridLayout }) => gridLayout.gridArea.rowStart === row,
  )
  if (allFieldsInRow.length === gridSize.cols) {
    return -1
  }

  const TAKEN = 'T'
  const rowData = new Array(gridSize.cols).fill('F')

  allFieldsInRow.forEach(({ gridLayout }) => {
    const { columnStart, columnEnd } = gridLayout.gridArea
    const colSpan = columnEnd - columnStart
    for (let i = 0; i < colSpan; i++) {
      rowData[columnStart - 1 + i] = TAKEN
    }
  })

  const rowInfo = rowData.join('')
  const newCell = new Array(cellSize.cols).fill('F').join('')
  return rowInfo.indexOf(newCell) + 1
}

const requestEmptyCellInGrid = async (
  createRowCallback,
  {
    allFieldsData,
    fieldType,
    containerLayout,
  }: { allFieldsData: FieldWithGridLayouts[]; fieldType; containerLayout: GridLayout },
) => {
  const containerGridSize = getContainerGridSize(containerLayout)
  const fieldsWithCurrentBreakpoint: FieldWithGridLayout[] = allFieldsData.map((f) => ({
    ...f,
    gridLayout:
      f.gridLayouts.find((l) => l.breakpointId === containerLayout.breakpointId) ||
      f.gridLayouts.find((l) => !l.breakpointId),
  }))
  const newCellSize: GridSize = _getCommonCellSize(
    fieldsWithCurrentBreakpoint.find((f) => f.fieldType === fieldType),
  )

  const { found, lastIndex, gridArea } = findEmptyCellInGrid(
    containerGridSize,
    fieldsWithCurrentBreakpoint,
    newCellSize,
  )
  let emptyCell: GridArea = null
  if (found) {
    emptyCell = gridArea
  } else {
    const newRowSize = getRowSize(containerLayout)
    const columnStart = 1
    const stepToNextRow = 1
    const rowStart = lastIndex + stepToNextRow
    await createRowCallback(lastIndex, newRowSize, containerLayout.breakpointId)
    emptyCell = {
      rowStart,
      rowEnd: rowStart + newCellSize.rows,
      columnStart,
      columnEnd: columnStart + newCellSize.cols,
    }
  }
  return emptyCell
}

const _getCommonCellSize = (field: FieldWithGridLayout) => {
  return field ? getItemGridSize(field.gridLayout) : fieldGridSize()
}

const isGridRowEmpty = (childrenPositionsCurrentBreakpoint: GridItemPosition[], row: number) =>
  childrenPositionsCurrentBreakpoint.every(
    (gridItem) => gridItem.gridArea.rowStart > row || gridItem.gridArea.rowEnd - 1 < row,
  )

const getGridLayouts = (componentDefinition?: ComponentDefinition) =>
  componentDefinition?.layoutResponsive?.itemLayouts
    ?.filter((item) => item.type === 'GridItemLayout')
    .map((item) => ({
      gridArea: (item as GridItemLayout).gridArea,
      breakpointId: (item as GridItemLayout).breakpoint,
    }))

export {
  createItemLayout,
  findNewFieldStackOrder,
  getItemLayoutData,
  calcUpdatesForStackFieldsByNewOrder,
  getContainerType,
  getContainerGridSize,
  getItemLayoutType,
  findEmptyCellInGrid,
  getItemGridSize,
  fieldGridSize,
  getComponentLayout,
  getItemLayout,
  getRowSize,
  requestEmptyCellInGrid,
  isGridRowEmpty,
  getGridLayouts,
}
