import { FieldNameType, COMPONENT_TYPES } from '@wix/forms-common'
import { FormFieldDefinitionFieldType } from '@wix/ambassador-wix-form-builder-web/types'
import { FieldDataType } from '@wix/ambassador-contacts-v4-extended-field/types'

export interface Field {
  category: string
  subCategory?: string
  value: FieldPreset
  name: string
  customFieldId?: string
  customFieldKey?: string
  isPremium?: boolean
  dependsOn?: string[]
  tooltip?: string
  crmType?: string
  nonPrimaryConnections?: ComponentConnection[]
  componentRef?: ComponentRef
  isNew?: boolean
}

export interface MissingField {
  type: FieldNameType
  name: string
  tab?: string
}

export type CustomField = {
  name: string
  fieldType: FieldDataType
}

export type CustomFieldResponse = {
  id?: string
  key: string
  name: string
  fieldType: FieldDataType
}

export const CRM_LABEL_MAX_LENGTH = 150

export const InputTypes = [
  COMPONENT_TYPES.TEXT_INPUT,
  COMPONENT_TYPES.TEXT_AREA,
  COMPONENT_TYPES.CHECKBOX,
  COMPONENT_TYPES.DATE_PICKER,
  COMPONENT_TYPES.RADIO_GROUP,
  COMPONENT_TYPES.CHECKBOX_GROUP,
  COMPONENT_TYPES.COMBO_BOX_INPUT,
  COMPONENT_TYPES.FILE_UPLOADER,
  COMPONENT_TYPES.TIME_PICKER,
  COMPONENT_TYPES.RATINGS_INPUT,
  COMPONENT_TYPES.SIGNATURE_INPUT,
  COMPONENT_TYPES.RICH_TEXT_BOX,
  COMPONENT_TYPES.SELECTION_TAGS_LIST,
  COMPONENT_TYPES.SLIDER,
  COMPONENT_TYPES.TOGGLE,
  COMPONENT_TYPES.ADDRESS_INPUT,
  COMPONENT_TYPES.CAPTCHA,
]

export { FormFieldDefinitionFieldType as ServerFieldType }
