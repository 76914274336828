export const PANEL = {
  EDIT_FORM: 'EDIT_FORM',
  EDIT_FIELD: 'EDIT_FIELD',
  SETTINGS_PANEL: 'SETTINGS_PANEL',
  SUBMIT_BUTTON_SETTINGS: 'SUBMIT_BUTTON_SETTINGS',
  EDIT_PAYMENT_FORM_LIST_ITEM: 'EDIT_PAYMENT_FORM_LIST_ITEM',
}

export enum WizardStep {
  Start = 'start',
  Promo = 'promo',
  Step1 = 'choose-payment-form',
  Step2 = 'selected-payment-option',
  Step3 = 'how-to-get-paid',
  Step3b = 'ascend-plan',
  End = 'end',
}
