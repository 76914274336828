import webBiLogger from '@wix/web-bi-logger'
import { BI } from '../constants/bi-common'
import { sanitizePII } from '@wix/bi-logger-sanitizer/dist/src/lib/sanitizers'
import { EditorType } from '@wix/platform-editor-sdk'
import { getAppVersion } from './utils'
import { Logger } from '@wix/web-bi-logger/dist/src/types'

const sanitizeBiLogger = (fields = {}) => {
  const sanitizedFields = {}

  Object.keys(fields).forEach((fieldName) => {
    sanitizedFields[fieldName] = sanitizePII(fields[fieldName])
  })

  return sanitizedFields
}

export const initBiLogger = ({
  endpoint = BI.ENDPOINT,
  src = BI.SRC,
  defaults = {},
} = {}): Logger => {
  const appVersion = getAppVersion()
  const logger = webBiLogger
    .factory({ endpoint })
    .updateDefaults({ src, appVersion, ...defaults })
    .logger()

  return {
    report: logger.report,
    flush: logger.flush,
    updateDefaults: logger.updateDefaults,
    log: (fields) => {
      const sanitizedFields = sanitizeBiLogger(fields)
      return logger.log(sanitizedFields)
    },
  }
}

export const initBiLoggerForEditorApp = (msid, uuid, builderOrigin: EditorType) => {
  return initBiLogger({
    defaults: { msid, builderOrigin, uuid },
  })
}
