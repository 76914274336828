import { FormsFieldPreset } from '@wix/forms-common/dist/src/constants/field-types'
import resourcesVersion from './resources-version.json'

const FORM_BUILDER_RESOURCES_BUILD = resourcesVersion.version
const WIX_STATIC_PATHS = 'https://static.parastorage.com/services/form-builder-resources'
const TRANSLATIONS_PATH = 'assets/translated'
export const PRESET_STATIC_URL = `${WIX_STATIC_PATHS}/${FORM_BUILDER_RESOURCES_BUILD}/${TRANSLATIONS_PATH}`
export const THEMES_STATIC_URL = `${WIX_STATIC_PATHS}/${FORM_BUILDER_RESOURCES_BUILD}/assets/themes/images`
export const SPECIFIC_COMPONENTS_THEMES_STATIC_URL = `${THEMES_STATIC_URL}/components`
export const MARKETING_IMAGES_STATIC_URL = `${WIX_STATIC_PATHS}/${FORM_BUILDER_RESOURCES_BUILD}/assets/panels/form-settings`
export const THEMES_COMPONENTS_STATIC_URL = `${WIX_STATIC_PATHS}/${FORM_BUILDER_RESOURCES_BUILD}/assets/themes/generated-components`
export const ADD_PANEL_COMPONENTS_PRESET_STATIC_URL = `${WIX_STATIC_PATHS}/${FORM_BUILDER_RESOURCES_BUILD}/assets/themes/components/addPanelPresets`

export const COMPONENTS_WITH_OVERRIDING_PRESET = [FormsFieldPreset.COMPLEX_PHONE_WIDGET]
