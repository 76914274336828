import { CATEGORIES } from '../../manage-fields-panel/constants/manage-fields-constants'

export const DividerSign = '-'

export const DEFAULT_VISUAL_CATEGORIES_V2 = [
  CATEGORIES.contact,
  CATEGORIES.basic,
  CATEGORIES.advanced,
  DividerSign,
  CATEGORIES.recaptcha,
]

export const DEFAULT_VISUAL_CATEGORIES = [
  CATEGORIES.recommended,
  CATEGORIES.text,
  CATEGORIES.number,
  CATEGORIES.recaptcha,
  CATEGORIES.selection,
  CATEGORIES.date,
  CATEGORIES.upload,
  CATEGORIES.subscription,
  CATEGORIES.signature,
]

export const orderByCategory = {
  basic: [
    CATEGORIES.text,
    CATEGORIES.number,
    CATEGORIES.selection,
    CATEGORIES.date,
    CATEGORIES.url,
    CATEGORIES.subscription,
    CATEGORIES.recaptcha,
  ],
  selection: [CATEGORIES.selection, CATEGORIES.subscription],
}
