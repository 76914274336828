import _ from 'lodash'
import { DynamicContactFormEnhancer, skinWithCenterMessages } from './contact-form-service'
import { ROLE_MESSAGE, FIELDS_ROLES, FIELDS } from '../../../constants/roles'

export const enhanceFormWithDesktopStructure: DynamicContactFormEnhancer = (
  contactForm: DynamicContactForm,
  convertedForm: RawComponentStructure,
  { desktopLayouts: layouts },
) => {
  return {
    ...convertedForm,
    components: convertedForm.components.map((component, index) => {
      if (component.role === FIELDS.ROLE_FIELD_COMPLEX_PHONE_WIDGET) {
        return getComplexPhoneLayout(contactForm, index, layouts, component)
      } else {
        const inputLayout = calcComponentLayout(contactForm, layouts[index], component)
        return _.includes(FIELDS_ROLES, component.role)
          ? inputLayout
          : { ...component, layout: inputLayout.layout }
      }
    }),
  }
}

export const getLayout = (
  { skin, contactFormWidth },
  component: Partial<RawComponentStructure>,
  compLayout,
): { layout: FieldLayout; inputHeight: number | null } => {
  const labelLayout = _.get(compLayout, '[1]')
  const inputLayout = _.get(compLayout, '[0].height') + 1
  const layout = _.omit(
    labelLayout
      ? {
          ...labelLayout,
          y: labelLayout.y + 8,
          height: labelLayout.height + inputLayout,
        }
      : compLayout,
    'fontSize',
  )

  if (component.role === ROLE_MESSAGE && _.includes(skinWithCenterMessages, skin)) {
    return { layout: { ...layout, x: 0, width: contactFormWidth }, inputHeight: null }
  }

  return { layout, inputHeight: Math.max(inputLayout || _.get(compLayout, 'height'), 25) }
}

const calcComponentLayout = (
  contactForm: DynamicContactForm,
  compLayout,
  component: Partial<RawComponentStructure>,
) => {
  const { layout, inputHeight } = getLayout(
    { skin: contactForm.skin, contactFormWidth: contactForm.layout.width },
    component,
    compLayout,
  )
  return {
    ...component,
    layout,
    ...(component.props
      ? {
          props: {
            ...component.props,
            inputHeight,
            textPadding: component.props.textPadding || 5,
            labelMargin: 5,
          },
        }
      : {}),
  }
}

const getComplexPhoneLayout = (
  contactForm: DynamicContactForm,
  index: number,
  layouts,
  component: Partial<RawComponentStructure>,
) => {
  const widget = component
  const [container] = component.components
  const [dropdown, text] = container.components

  const [widgetOriginalLayout, dropdownOriginalLayout, textOriginalLayout] = layouts[index] || []

  const widgetLayout = calcComponentLayout(contactForm, widgetOriginalLayout, widget).layout
  const containerLayout = calcComponentLayout(contactForm, widgetOriginalLayout, container).layout

  const dropdownWithLayout = calcComponentLayout(contactForm, dropdownOriginalLayout, dropdown)
  const textWithLayout = calcComponentLayout(contactForm, textOriginalLayout, text)

  return {
    ...component,
    layout: widgetLayout,
    components: [
      {
        ...container,
        layout: containerLayout,
        components: [dropdownWithLayout, textWithLayout],
      },
    ],
  }
}
