import { IGeneralField } from '../../general-field'
import { ComplexAddressWidget } from './definitions/complex-address-widget'
import { ComplexAddressStreet } from './definitions/complex-address-street'
import { ComplexAddressStreet2 } from './definitions/complex-address-street-2'
import { ComplexAddressCity } from './definitions/complex-address-city'
import { ComplexAddressZipCode } from './definitions/complex-address-zipcode'
import { ComplexAddressCountry } from './definitions/complex-address-country'
import { ComplexAddressState } from './definitions/complex-address-state'

export const createComplexAddressField = (): IGeneralField[] => [
  new ComplexAddressWidget(),
  new ComplexAddressStreet(),
  new ComplexAddressStreet2(),
  new ComplexAddressCity(),
  new ComplexAddressState(),
  new ComplexAddressZipCode(),
  new ComplexAddressCountry(),
]
