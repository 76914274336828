import { FontOption } from '@wix/platform-editor-sdk'
import colorString from 'color-string'
import _ from 'lodash'
import { getFontFamily, changeFontFamily, removeFontColor } from './form-style-service'
// Skin definition: wysiwyg.viewer.skins.button.BasicButton.scss + wysiwyg.viewer.skins.button.BasicButton.html
export interface ButtonSkinProperties {
  bg?: string // #link         { background-color: $bg }    | .root                 { background: value($bg) / background: $bg }
  bgh?: string // :hover #link  { background-color: $bgh }   | .root:hover           { background: value($bgh) / background: $bgh }
  brd?: string // #link         { border: solid $brd $brw }  | .root                 { border: $brw solid value($brd) / border: $brw solid $brd }
  brdh?: string // :hover #link  { border-color: $brdh }      | .root:hover           { border: $brw solid value($brdh) / border: $brw solid $brdh }
  brw?: string // #link         { border: solid $brd $brw }  | .root                 { border: $brw solid value($brd) / border: $brw solid $brd }
  fnt?: string // #label        { font: $fnt }               | .root::label          { -st-mixin: $fnt / font: $fnt }
  rd?: string // #link         { border-radius: $rd }       | .root                 { border-radius: $rd }
  shd?: string // #link         { box-shadow: $shd }         | .root                 { box-shadow: $shd }
  txt?: string // #label        { color: $txt }              | .root::label          { color: value($txt) / color: $txt }
  txth?: string // :hover #label { color: $txt }              | .root:hover::label    { color: value($txth) / color: $txth }

  trans1?: string // #link       { transition: $trans1 }      | .root                 { transition: $trans1 }
  trans2?: string // #label      { transition: $trans2 }      | .root::label          { transition: $trans2 }

  // [data-disabled="true"]
  bgd?: string // #link       { background-color: $bgd }   | .root:disabled        { background: value($bgd) / background: $bgd }
  brdd?: string // #link       { border: solid $brdd $brw } | .root:disabled        { border: $brw solid value($brdd) / border: $brw solid $brdd }
  txtd?: string // #label      { color: $txtd }             | .root:disabled::label { color: value($txtd) / color: $txtd }

  // Not in skin CSS definition
  'alpha-bg'?: string
  'alpha-bgh'?: string
  'alpha-brd'?: string
  'alpha-brdh'?: string
  'alpha-txt'?: string
  'alpha-txth'?: string
  'boxShadowToggleOn-shd'?: string
  'alpha-bgd'?: string
  'alpha-brdd'?: string
  'alpha-txtd'?: string

  direction?: string
  padding?: string
}
export type ButtonSkinProperty = keyof ButtonSkinProperties

const THEME_COLOR_KEY = 'color'
const THEME_FONT_KEY = 'font'
const THEME_KEY_DELIMITER = '_'

const STYLABLE_FONT_PROP = 'font'
const STYLABLE_MIXIN_PROP = '-st-mixin'
const COLOR_OFFSET = 11
const MATRIX_DIMENSION = 5
const COMPONENT_NAME = 'StylableButton'

export const DEFAULT_BUTTON_SKIN_PROPERTIES: ButtonSkinProperties = {
  bg: 'color_17',
  bgh: 'color_18',
  brd: 'color_15',
  brdh: 'color_15',
  brw: '0',
  fnt: 'font_5',
  rd: '0',
  shd: '0 1px 4px rgba(0, 0, 0, .6)',
  txt: 'color_15',
  txth: 'color_15',
  // trans1: 'border-color .4s ease 0s, background-color .4s ease 0s',
  trans1: 'all 0.2s ease, visibility 0s',
  // trans2: 'color .4s ease 0s',
  trans2: 'inherit',
  bgd: '#E2E2E2',
  brdd: 'color_15',
  txtd: '#8F8F8F',
  'alpha-bg': '1',
  'alpha-bgh': '1',
  'alpha-brd': '1',
  'alpha-brdh': '0',
  'alpha-txt': '1',
  'alpha-txth': '1',
  'alpha-bgd': '1',
  'alpha-brdd': '1',
  'alpha-txtd': '1',
  'boxShadowToggleOn-shd': 'false',
  direction: 'ltr',
  padding: '5px',
}

const THEME_PREFIX = (themeKey: string) => `${themeKey}${THEME_KEY_DELIMITER}`
const isTheme = (value: string, themeKey: string) => value.startsWith(THEME_PREFIX(themeKey))
const getColorProperty = (value: string, colorMap: ColorPalette) =>
  isTheme(value, THEME_COLOR_KEY)
    ? colorMap
      ? getFromColorMap(value, colorMap)
      : generateSiteColorVariable(getThemeIndex(value, THEME_COLOR_KEY) - COLOR_OFFSET)
    : value

const getFontProperty = (value: string, fontOptions) => {
  if (isTheme(value, THEME_FONT_KEY)) {
    return `${STYLABLE_MIXIN_PROP}: ${value}`
  }
  const fontFamily = getFontFamily(value, fontOptions)
  const valueWithoutColor = removeFontColor(value)
  const valueWithFont = fontFamily
    ? changeFontFamily(valueWithoutColor, fontFamily)
    : valueWithoutColor

  return `${STYLABLE_FONT_PROP}: ${valueWithFont}`
}

const getThemeIndex = (value: string, themeKey: string) =>
  parseInt(value.replace(THEME_PREFIX(themeKey), ''), 10)

const xFromIndex = (index: number) => Math.floor(index / MATRIX_DIMENSION) + 1
const yFromIndex = (index: number) => Math.floor(index % MATRIX_DIMENSION) + 1
const generateSiteColorVariable = (index: number) =>
  `value(site_${xFromIndex(index)}_${yFromIndex(index)})`

const uglifyStylableCSS = (value: string) => value.replace(/\n/g, '').replace(/\s\s+/g, '')

export const generateStylableButtonStCss = (
  properties: ButtonSkinProperties,
  colorMap: ColorPalette,
  fontOptions: FontOption[],
  defaultProperties: ButtonSkinProperties = DEFAULT_BUTTON_SKIN_PROPERTIES,
  noUglify = false,
) => {
  const getProperty = (name: ButtonSkinProperty) =>
    !_.isUndefined(properties[name]) ? properties[name] : defaultProperties[name] || ''
  const getColor = (name: ButtonSkinProperty, saveTheme: boolean) =>
    getColorProperty(getProperty(name), !saveTheme && colorMap)
  const getFont = (name: ButtonSkinProperty) => getFontProperty(getProperty(name), fontOptions)
  const getAlphaProperty = (name: ButtonSkinProperty) => {
    const alpha = Number(getProperty(`alpha-${name}` as ButtonSkinProperty))
    const index = getThemeIndex(getProperty(name), THEME_COLOR_KEY)
    return alpha === 1 && index >= COLOR_OFFSET
      ? getColor(name, true)
      : getRGBAColorString(getColor(name, false), alpha)
  }
  const getBorderWidth = (name: ButtonSkinProperty) => {
    const value = getProperty(name)
    return value.endsWith('px') ? value : `${value}px`
  }

  const output = `:import{
  -st-from: 'wix-ui-santa/index.st.css';
  -st-named: ${COMPONENT_NAME}
}
.root{
  -st-extends: ${COMPONENT_NAME};
  transition: ${getProperty('trans1')};
  background: ${getAlphaProperty('bg')};
  border: ${getBorderWidth('brw')} solid ${getAlphaProperty('brd')};
  border-radius: ${getProperty('rd')};
  box-shadow: ${getProperty('boxShadowToggleOn-shd') === 'false' ? 'none' : getProperty('shd')}
}
.root:hover{
  background: ${getAlphaProperty('bgh')};
  border: ${getBorderWidth('brw')} solid ${getAlphaProperty('brdh')}
}
.root:hover::label{
  color: ${getAlphaProperty('txth')}
}
.root:disabled{
  background: ${getAlphaProperty('bgd')};
  border: ${getBorderWidth('brw')} solid ${getAlphaProperty('brdd')}
}
.root:disabled::label{
  color: ${getAlphaProperty('txtd')}
}
.root::container{
  transition: inherit
}
.root::label{
  transition: ${getProperty('trans2')};
  white-space: normal;
  text-overflow: clip;
  color: ${getAlphaProperty('txt')};
  direction: ${getProperty('direction')};
  padding: ${getProperty('padding')};
  padding-top: 0px;
  padding-bottom: 0px;
  ${getFont('fnt')}
}
.root::icon{
  display: none
}`
  return noUglify ? output : uglifyStylableCSS(output)
}

export const baseStylableButton = {
  type: 'Component',
  skin: 'wixui.skins.Skinless',
  layout: {
    width: 240,
    height: 48,
    x: 113,
    y: 426,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'wixui.StylableButton',
  data: {
    type: 'StylableButton',
    metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false, pageId: '' },
    label: 'Submit',
    svgId: 'b861b040274141de9c08999ec3b76edf.svg',
    link: {
      type: 'FormSubmitButtonLink',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
      },
    },
  },
  props: {
    type: 'StylableButtonProperties',
    metaData: { schemaVersion: '1.0', isAutoGenerated: null, pageId: '' },
  },
  role: 'button_Role',
  config: {},
  style: {
    type: 'ComponentStyle',
    styleType: 'custom',
    metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false, pageId: 'c1dmp' },
    style: {
      properties: {
        '$st-css': '',
      },
      propertiesSource: { '$st-css': 'value' },
    },
    componentClassName: 'wixui.StylableButton',
    pageId: '',
    compId: '',
    skin: 'wixui.skins.Skinless',
  },
  activeModes: {},
}

const extractThemeColor = (str: string): string => {
  return str.replace(new RegExp('[\\[\\]{}]', 'g'), '')
}

const getFromColorMap = (rawColor: string, colorsMap: ColorPalette) => {
  const themeColorIndex = extractThemeColor(rawColor)
  return _.get(colorsMap, `${themeColorIndex}`) || rawColor
}

const isColor = (val: string): boolean =>
  val
    ? /(^#([a-f\d]{3}){1,2}$)|(^rgba?\(\d+(,\d+){2,3}(\.\d+)?\)$)/i.test(val.replace(/\s/g, ''))
    : false

const fixColor = (color: string) => {
  if (isColor(color)) {
    return color
  }
  if (typeof color === 'undefined') {
    return color
  }
  return color.replace(';', '')
}

const getRGBAColorString = (color: string, colorOpacity: number = 1): string => {
  const fixedColor = fixColor(color)
  if (colorOpacity === 1) {
    return fixedColor
  }
  const rgbColor = colorString.get.rgb(fixedColor)
  rgbColor[3] = colorOpacity
  return colorString.to.rgb(rgbColor)
}

export const isStylableButton = (componentStructure: ComponentStructure | RawComponentStructure) =>
  componentStructure.componentType === 'wixui.StylableButton'
