import { IGeneralField } from '../general-field'
import { AdiAddress } from './definitions/adi-address'
import { AdiPhone } from './definitions/adi-phone'
import { AdiEmail } from './definitions/adi-email'
import { AdiUrl } from './definitions/adi-url'
import { AdiNumber } from './definitions/adi-number'
import { AdiDate } from './definitions/adi-date'
import { AdiFirstName } from './definitions/adi-first-name'
import { AdiLastName } from './definitions/adi-last-name'
import { AdiGeneralText } from './definitions/adi-general-text'
import { AdiGeneralTextBox } from './definitions/adi-general-text-box'
import { AdiGeneralUploadButton } from './definitions/adi-general-upload-button'
import { AdiGeneralDropdown } from './definitions/adi-general-dropdown'

export const createAdiFields = (): IGeneralField[] => [
  new AdiEmail(),
  new AdiPhone(),
  new AdiAddress(),
  new AdiUrl(),
  new AdiNumber(),
  new AdiDate(),
  new AdiDate(),
  new AdiFirstName(),
  new AdiLastName(),
  new AdiGeneralText(),
  new AdiGeneralTextBox(),
  new AdiGeneralUploadButton(),
  new AdiGeneralDropdown(),
]
