import _ from 'lodash'
import { COMPLEX_ADDRESS_ROLES } from '../../../constants/roles'
import { getFieldByRole } from '../fields/utils'
import { editorAppMetaData } from '../../forms-editor-app/editor-app'
import { makeGeneralDropdownOption } from '../preset/fields/general-fields/definitions/general-dropdown'

export const getStreetField = (subFields: FormField[]): FormField => {
  return getFieldByRole(subFields, COMPLEX_ADDRESS_ROLES.STREET)
}
export const getStreet2Field = (subFields: FormField[]): FormField => {
  return getFieldByRole(subFields, COMPLEX_ADDRESS_ROLES.STREET2)
}
export const getCityField = (subFields: FormField[]): FormField => {
  return getFieldByRole(subFields, COMPLEX_ADDRESS_ROLES.CITY)
}
export const getCountryField = (subFields: FormField[]): FormField => {
  return getFieldByRole(subFields, COMPLEX_ADDRESS_ROLES.COUNTRY)
}
export const getStateField = (subFields: FormField[]): FormField => {
  return getFieldByRole(subFields, COMPLEX_ADDRESS_ROLES.STATE)
}
export const getZipCodeField = (subFields: FormField[]): FormField => {
  return getFieldByRole(subFields, COMPLEX_ADDRESS_ROLES.ZIP_CODE)
}
export const getCountryOptions = (subFields: FormField[]): DropdownOption[] => {
  const options = _.get(getCountryField(subFields), 'options') || []
  const filteredOptions: DropdownOption[] = editorAppMetaData
    .getLocaleData()
    .getAllCountries(options.map((o) => o.value))
    .map((o) => ({ ...makeGeneralDropdownOption(), text: o.label, value: o.value }))
  return filteredOptions
}
