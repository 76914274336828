import _ from 'lodash'
import { handleError, captureBreadcrumb } from '../forms-editor-app/monitoring'
import { safelyStringify } from '../../utils/utils'

export const addToHistory = (boundEditorSDK: BoundEditorSDK): Promise<void> =>
  boundEditorSDK.history.add({ label: 'History' })

export const undoable = () => (_target, _funcName, decorator) => {
  const originalMethod = decorator.value
  decorator.value = async function (...args) {
    const res = await originalMethod.apply(this, args)
    await addToHistory(this.boundEditorSDK)
    return res
  }
}

export const absorbException =
  (category, fallbackValue = undefined) =>
  (_target, funcName, decorator) => {
    const originalMethod = decorator.value
    decorator.value = async function (...args) {
      captureBreadcrumb({
        message: funcName,
        category,
        level: 'info',
        data: { args: safelyStringify(args) },
      })

      try {
        const res = await originalMethod.apply(this, args)
        return res
      } catch (err) {
        const stringifiedArgs = safelyStringify(args)
        handleError(err, {
          tags: { funcName, absorbed: true },
          extra: {
            args: stringifiedArgs,
            fallbackValue,
          },
        })

        return Promise.resolve(fallbackValue)
      }
    }
  }

const promiseQueue = []

export const dequeue = () => {
  promiseQueue.splice(0, 1)
}

export const withSync = () => (_target, _funcName, decorator) => {
  const originalMethod = decorator.value
  decorator.value = function (...args) {
    let promise
    const applyMethod = () => originalMethod.apply(this, args)
    if (promiseQueue.length === 0) {
      promise = applyMethod()
    } else {
      promise = promiseQueue[promiseQueue.length - 1].then(applyMethod).catch(applyMethod)
    }
    promiseQueue.push(promise.then(dequeue).catch(dequeue))
    return promise
  }
}

export const withBi =
  ({ startEvid = {}, endEvid = {} } = {}) =>
  (_target, _funcName, decorator) => {
    const isBiData = (arg) =>
      _.get(arg, 'startBi') !== undefined || _.get(arg, 'endBi') !== undefined
    const originalMethod = decorator.value

    decorator.value = async function (...args) {
      let res
      const biData = args[args.length - 1]

      if (isBiData(biData)) {
        if (!_.isEmpty(biData.startBi)) {
          this.biLogger.log({ evid: startEvid, ...biData.startBi })
        }
        res = await originalMethod.apply(this, args.slice(0, -1))
        if (!_.isEmpty(biData.endBi)) {
          this.biLogger.log({ evid: endEvid, ...biData.endBi })
        }
      } else {
        res = await originalMethod.apply(this, args)
      }

      return res
    }
  }

export const withFedops = (interactionName) => (_target, _funcName, decorator) => {
  const originalMethod = decorator.value

  decorator.value = async function (...args) {
    if (this.fedopsLogger) {
      this.fedopsLogger.interactionStarted(interactionName)
    }

    const res = await originalMethod.apply(this, args)

    if (this.fedopsLogger) {
      this.fedopsLogger.interactionEnded(interactionName)
    }

    return res
  }
}
