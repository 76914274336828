import { AdiFieldPreset } from '@wix/forms-common'
import { GeneralNumber } from '../../general-fields/definitions/general-number'

export class AdiNumber extends GeneralNumber {
  public get fieldType() {
    return AdiFieldPreset.ADI_NUMBER
  }

  protected get data() {
    return {
      textType: 'number',
      placeholder: this.translate(`adi.fieldTypes.${this.base.metadata.fieldType}.placeholder`),
      label: this.translate(`adi.fieldTypes.${this.base.metadata.fieldType}.label`),
    }
  }

  protected get crmLabel() {
    return this.translate(`fieldTypes.${this.base.metadata.fieldType}`)
  }

  protected get ADI_titleTranslationKey() {
    return `adi.${this.base.metadata.fieldType}FieldEditPanel.title`
  }
}
