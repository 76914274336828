export enum PanelName {
  ADD_FORM = 'addFormPanel',
  FORM_MANAGE_FIELDS = 'manageFieldsPanel',
  FORM_SETTINGS = 'settingsPanel',
  NEW_FORM_SETTINGS = 'formSettingsPanel',
  FORM_STYLE = 'formStylePanel',
  FORM_LAYOUT = 'formLayoutPanel',
  FIELD_SETTINGS = 'fieldSettingsPanel',
  SUBMIT_SETTINGS = 'submitSettingsPanel',
  ADD_FIELD = 'addFieldPanel',
  UPGRADE_ALERT = 'upgradeAlertPanel',
  FORM_MANAGE_SUBSCRIBERS = 'manageSubscribersPanel',
  SITE_MEMBERS_SETTINGS = 'panels.focusPanels.siteMembersSettingsPanel',
  CONNECT_FIELD = 'connectFieldPanel',
  RECAPTCHA_HELP = 'recaptchaHelp',
  PUBLISH_SITE_POP_UP = 'publishSitePop',
  PAYMENT_WIZARD = 'paymentWizardPanel',
  DELETE_PAYMENT_ALERT = 'deletePaymentAlert',
  ADI_EDIT_FORM = 'adiEditFormPanel',
  ADI_EDIT_FIELD = 'adiEditFieldPanel',
  MANAGE_STEPS = 'manageSteps',
  CHANGE_BUTTON_LABEL = 'changeButtonLabel',
  CONTACT_SYNC_PANEL = 'contactSyncPanel',
  DATE_PICKER_PANEL = 'datePickerPanel',
  ITEMS_SELECTOR_PANEL = 'itemsSelectorPanel',
  WARNING = 'warning',
  COMPLEX_PHONE_LAYOUT = 'complexPhoneLayoutPanel',
  COMPLEX_PHONE_SETTINGS = 'complexPhoneSettingsPanel',
  COMPLEX_ADDRESS_LAYOUT = 'complexAddressLayoutPanel',
  COMPLEX_ADDRESS_SETTINGS = 'complexAddressSettingsPanel',
  COMPLEX_STYLE = 'complexStylePanel',
}

export enum NativePanelName {
  SETTINGS = 'settings',
}

export enum ModalSkins {
  Responsive = 'responsive-skin',
  NewWorkspace = 'classic-facelift-skin',
  Classic = 'classic',
}
