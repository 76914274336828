import { ResponsiveFormPreset, ResponsiveGridFormPreset } from '@wix/forms-common'
import { FormPreset } from '../../../constants/form-types'

export const PRESET_TYPES = [
  FormPreset.CONTACT_FORM,
  FormPreset.GET_SUBSCRIBERS,
  FormPreset.PAYMENT_FORM,
  FormPreset.MULTI_STEP_REGISTRATION_FORM,
  FormPreset.SALES_LEAD,
  FormPreset.SUPPORT_REQUEST,
  FormPreset.TESTIMONIAL,
  FormPreset.JOB_APPLICATION,
  FormPreset.CUSTOMER_SATISFACTION,
  FormPreset.PRICE_QUOTE,
  FormPreset.CONTEST_ENTRY,
  FormPreset.QUIZ,
]

export const PRESET_TYPES_WITHOUT_LABELS = [
  FormPreset.CONTACT_FORM,
  FormPreset.SALES_LEAD,
  FormPreset.SUPPORT_REQUEST,
  FormPreset.TESTIMONIAL,
  FormPreset.JOB_APPLICATION,
  FormPreset.CUSTOMER_SATISFACTION,
  FormPreset.PRICE_QUOTE,
  FormPreset.CONTEST_ENTRY,
  FormPreset.QUIZ,
]

export const RESPONSIVE_PRESET_TYPES = [
  {
    contentId: FormPreset.CONTACT_FORM,
    preset: ResponsiveFormPreset.CONTACT,
  },
  {
    contentId: FormPreset.GET_SUBSCRIBERS,
    preset: ResponsiveFormPreset.SUBSCRIBERS,
  },
  {
    contentId: FormPreset.SALES_LEAD,
    preset: ResponsiveFormPreset.SALES_LEAD,
  },
  {
    contentId: FormPreset.JOB_APPLICATION,
    preset: ResponsiveFormPreset.JOB_APPLICATION,
  },
]

export const RESPONSIVE_GRID_PRESET_TYPES = [
  {
    contentId: FormPreset.CONTACT_FORM,
    preset: ResponsiveGridFormPreset.CONTACT,
  },
  {
    contentId: FormPreset.GET_SUBSCRIBERS,
    preset: ResponsiveGridFormPreset.SUBSCRIBERS,
  },
  {
    contentId: FormPreset.SALES_LEAD,
    preset: ResponsiveGridFormPreset.SALES_LEAD,
  },
  {
    contentId: FormPreset.JOB_APPLICATION,
    preset: ResponsiveGridFormPreset.JOB_APPLICATION,
  },
]

export const BASE_RULE_EVENT_SCHEMA = {
  'contact.Name.First': {
    displayName: 'Contact First Name',
    type: 'string',
  },
  'contact.Name.Middle': {
    displayName: 'Contact Middle Name',
    type: 'string',
  },
  'contact.Name.Last': {
    displayName: 'Contact Last Name',
    type: 'string',
  },
  'contact.Email[0]': {
    displayName: 'Contact First Email',
    type: 'string',
  },
  'contact.Email[1]': {
    displayName: 'Contact Second Email',
    type: 'string',
  },
  'contact.Email[2]': {
    displayName: 'Contact Third Email',
    type: 'string',
  },
  'contact.Phone[0]': {
    displayName: 'Contact First Phone',
    type: 'string',
  },
  'contact.Phone[1]': {
    displayName: 'Contact Second Phone',
    type: 'string',
  },
  'contact.Phone[2]': {
    displayName: 'Contact Third Phone',
    type: 'string',
  },
  'contact.Address[0].Country': {
    displayName: 'Contact Country',
    type: 'string',
  },
  'contact.Address[0].Street': {
    displayName: 'Contact Street',
    type: 'string',
  },
  'contact.Address[0].City': {
    displayName: 'Contact City',
    type: 'string',
  },
  'contact.Address[0].Zip': {
    displayName: 'Contact Zip',
    type: 'string',
  },
}
