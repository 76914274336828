import _ from 'lodash'
import CoreApi from '../core-api'
import RemoteApi from '../../../panels/commons/remote-api'
import { AppStateBuilder } from './app-state-builder'
import { ROLE_FORM } from '../../../constants/roles'
import { FormPlugin } from '@wix/forms-common'
import { getPluginAppState } from '../plugins/pluginAppStates'

export default class AppState {
  private boundEditorSDK: BoundEditorSDK
  private coreApi: CoreApi
  private experiments: any
  private remoteApi: RemoteApi

  constructor(boundEditorSDK, coreApi: CoreApi, remoteApi, { experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.remoteApi = remoteApi
    this.experiments = experiments
  }

  private async _getFormAppStatePlugins(formRef: ComponentRef) {
    const plugins = await this.coreApi.getFormPlugins(formRef)
    return getPluginAppState(plugins).plugins
  }

  public async setState(controllerRefs?: ComponentRef[]) {
    if (!controllerRefs) {
      const controllers: {
        controllerRef: ComponentRef
      }[] = await this.boundEditorSDK.controllers.listAllControllers()
      controllerRefs = controllers.map(({ controllerRef }) => controllerRef)
    }

    controllerRefs.forEach(async (ref) => {
      const form = await this.coreApi.findConnectedComponent(ref, ROLE_FORM)

      if (!form) {
        return
      }

      // just appStatePlugins
      const plugins = await this._getFormAppStatePlugins(form.ref)
      const duplicatable =
        (await this.coreApi.isAppWidget(ref)) && !_.includes(plugins, FormPlugin.REGISTRATION_FORM)

      const stateBuilder = new AppStateBuilder({
        plugins,
        duplicatable,
      })

      const stateName = stateBuilder.toString()
      if (stateName) {
        await this.boundEditorSDK.controllers.setState({ state: { [stateName]: [ref] } })
      }
    })
  }
}
