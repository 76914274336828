import { Email } from '../../recommended-fields/definitions/email'
import { CRM_TAGS } from '../../../../../../constants/crm-types-tags'
import { FormsFieldPreset } from '@wix/forms-common'

// contact form email type
export class MainEmail extends Email {
  public get crmTag() {
    return CRM_TAGS.MAIN
  }

  public get fieldType() {
    return FormsFieldPreset.MAIN_EMAIL
  }

  public get base() {
    return Email.prototype
  }
}
