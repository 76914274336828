import { Rule } from '@wix/forms-common'

export enum ConditionOperationUI {
  Filled = 'Filled',
  FilledWith = 'FilledWith',
  NotFilled = 'NotFilled',
}

export enum ActionOperationUI {
  Show = 'Shown',
  Hide = 'Hidden',
  Required = 'Required',
  Optional = 'Optional',
}

export interface ConditionUI {
  operation: ConditionOperationUI
  field: string
  args?: any
}

export interface ActionUI {
  operation: ActionOperationUI
  fields?: string[]
}

export interface RuleUI {
  conditions: ConditionUI[]
  actions: ActionUI[]
  enabled?: boolean
}

export enum RULES_UPDATE_STATUS {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export interface RulesData {
  rules: Rule[]
  rulesUpdateStatus: RULES_UPDATE_STATUS
}
