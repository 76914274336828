import { ExtendApiCreator } from '../extend-api'
import CoreApi from '../../core-api'
import _ from 'lodash'
import SubscribersApi from '../basicApis/get-subscribers'
import MultiStepFormApi from '../basicApis/multistep-form'

class GetSubscribersMultiStepFormApi extends ExtendApiCreator {
  subscribeApi: SubscribersApi
  multiStepApi: MultiStepFormApi

  constructor(coreApi: CoreApi) {
    super()
    this.subscribeApi = new SubscribersApi(coreApi)
    this.multiStepApi = new MultiStepFormApi(coreApi)
  }

  get events() {
    return this.subscribeApi.events
  }

  getExtendApi() {
    const subscribeApi = this.subscribeApi.getExtendApi()
    const multiStepApi = this.multiStepApi.getExtendApi()

    const subscribeNewFields = _.get(subscribeApi, 'fields.getNewFields')

    return {
      onEvent: this.createOnEvent(),
      'fields.getNewFields': subscribeNewFields,
      appConfig: async (payload) => {
        const [getSubscribersAppConfig, getMultiSelectAppConfig] = await Promise.all([
          subscribeApi.appConfig(payload),
          multiStepApi.appConfig(payload),
        ])

        return {
          features: {
            layout: getMultiSelectAppConfig.features.layout,
            doubleOptIn: getSubscribersAppConfig.features.doubleOptIn,
          },
          mainCTA: getSubscribersAppConfig.mainCTA,
        }
      },
    }
  }
}

export default GetSubscribersMultiStepFormApi
