import { getStaticsBaseUrl, getTranslationByPlugin } from '../../../utils/utils'
import translations from '../../../utils/translations'
import _ from 'lodash'
import { isMultiStepPlugin, isRegistrationFormPlugin } from '../plugins/utils'
import MULTI_STEP_HELP_IDS from '../../../plugins/multi-step-form/constants/help-ids'
import { InitialPanelData } from '../../../panels/commons/base-panel'
import { OwnSettingsPanelProps } from '../../../panels/form-settings-panel/components/form-settings-panel'
import { UpgradeAlertPanelOwnProps } from '../../../panels/upgrade-alert-panel/components/upgrade-alert-panel'
import { AddFieldOwnProps } from '../../../panels/add-field-panel/components/add-field-panel'
import { OwnManageStepsPanelPropsProps } from '../../../panels/manage-steps-panel/components/manage-steps-panel'
import { ChangeButtonLabelPanelOwnProps } from '../../../panels/change-button-label-panel/components/change-button-label-panel'
import { ConnectFieldPanelProps } from '../../../panels/connect-field-panel/components/connect-field-panel'
import { FormLayoutPanelOwnProps } from '../../../panels/form-layout-panel/components/form-layout-panel'
import { SubmitSettingsPanelOwnProps } from '../../../panels/submit-settings-panel/components/submit-settings-panel'
import { OwnPaymentWizardPanelProps } from '../../../panels/payment-wizard-panel/components/payment-wizard-panel'
import { OwnContactSyncPanelProps } from '../../../panels/contact-sync-panel/components/contact-sync-panel'
import { ItemsSelectorPanelOwnProps } from '../../../panels/items-selector-panel/components/items-selector-panel'
import { DatePickerPanelProps } from '../../../panels/date-picker-panel/components/date-picker-panel'
import { ComplexFieldLayoutPanelOwnProps } from '../../../panels/commons/complex-field-layout-panel/components/complex-field-layout-panel'
import { getPluginAppState } from '../plugins/pluginAppStates'
import { ModalSkins, PanelName } from '../../../constants/panel-names'
import { getPanelHeight } from './panel-definition-util'

const createUrlFor = (msid) => (panelName) =>
  `https://${getStaticsBaseUrl()}/${panelName}-panel.html?msid=${msid}`

export const createPanelDefs = (msid, skin = ModalSkins.Classic) => {
  const urlFor = createUrlFor(msid)
  return {
    [PanelName.ADD_FORM]: ({ isResponsive }) => {
      const useSkin = isResponsive ? ModalSkins.Responsive : skin
      return {
        ...sizesBySkin[PanelName.ADD_FORM][useSkin],
        title: translations.t('addForm.panelTitle'),
        helpId: 'e5d2c2d0-8f70-4140-b6ef-613327dbe3a8',
        url: urlFor('add-form'),
      }
    },
    [PanelName.FORM_MANAGE_SUBSCRIBERS]: () => ({
      shouldHideHeader: true,
      ...sizesBySkin[PanelName.FORM_MANAGE_SUBSCRIBERS][skin],
      url: urlFor('manage-subscribers'),
    }),
    [PanelName.NEW_FORM_SETTINGS]: (initialData: InitialPanelData<OwnSettingsPanelProps>) => ({
      title: getTranslationByPlugin({
        t: translations.t,
        prefix: 'formSettings',
        postfix: 'panelTitle',
        appStatePlugins: getPluginAppState(initialData.plugins).plugins,
      }),
      componentRef: initialData.componentRef,
      initialData,
      width: 441,
      height: 582,
      type: 'settings' as any,
      helpId: isMultiStepPlugin(initialData.plugins)
        ? MULTI_STEP_HELP_IDS.SETTINGS
        : '342ee9a5-b635-4669-bca7-6bfe756c8ab0',
      url: urlFor('form-settings'),
    }),
    [PanelName.MANAGE_STEPS]: (initialData: InitialPanelData<OwnManageStepsPanelPropsProps>) => ({
      title: translations.t('multiStepForm.manageSteps.panelTitle'),
      componentRef: initialData.componentRef,
      initialData,
      width: 288,
      height: 546,
      url: urlFor('manage-steps'),
      helpId: MULTI_STEP_HELP_IDS.MANAGE_STEPS,
    }),
    [PanelName.CHANGE_BUTTON_LABEL]: (
      initialData: InitialPanelData<ChangeButtonLabelPanelOwnProps>,
    ) => ({
      title: translations.t(`changeButtonLabelPanel.${_.camelCase(initialData.role)}.title`),
      componentRef: initialData.componentRef,
      initialData,
      width: 288,
      height: 151,
      url: urlFor('change-button-label'),
      helpId: MULTI_STEP_HELP_IDS.MANAGE_STEPS,
    }),
    [PanelName.CONNECT_FIELD]: (
      initialData: InitialPanelData<ConnectFieldPanelProps>,
      { mode, fieldRenderConfig = {}, experiments, appConfig, isResponsive },
    ) => {
      const useSkin = isResponsive ? ModalSkins.Responsive : skin
      return {
        title: translations.t('advancedSettignsPanel.panelTitle'),
        componentRef: initialData.componentRef,
        initialData,
        helpId: {
          desktop: 'be46ba62-113a-4fba-9805-fc251858005b',
          mobile: 'dad84a79-8701-408c-9174-454062221932',
        }[mode],
        url: urlFor('connect-field'),
        ...sizesBySkin[PanelName.CONNECT_FIELD][useSkin],
        height: getPanelHeight(PanelName.CONNECT_FIELD, {
          fieldRenderConfig,
          appConfig,
          isResponsive,
        }),
      }
    },
    [PanelName.FORM_LAYOUT]: (
      initialData: InitialPanelData<FormLayoutPanelOwnProps>,
      { mode, isResponsive, experiments },
    ) => {
      const shouldRenderTitleOptionInEdix = experiments.enabled('specs.crm.FormsEditorXShowLabels')
      const layoutPanelHeight = isResponsive
        ? shouldRenderTitleOptionInEdix
          ? 490
          : 203
        : isMultiStepPlugin(initialData.plugins)
        ? 490
        : 546

      return {
        title: translations.t('formStyle.layout.panelTitle'),
        componentRef: initialData.componentRef,
        initialData,
        width: 288,
        height: layoutPanelHeight,
        type: 'layout' as any,
        helpId: {
          desktop: isMultiStepPlugin(initialData.plugins)
            ? MULTI_STEP_HELP_IDS.LAYOUTS
            : '69c55c8d-6865-45ed-ab87-7ce70e1564f9',
          mobile: isMultiStepPlugin(initialData.plugins)
            ? MULTI_STEP_HELP_IDS.LAYOUTS
            : '7a4ec10c-f40f-4ebf-a475-0fbdd3978ad6',
        }[mode],
        url: urlFor('form-layout'),
      }
    },
    [PanelName.SUBMIT_SETTINGS]: (initialData: InitialPanelData<SubmitSettingsPanelOwnProps>) => {
      const showEnhancedSubmitSettingsPanel = !isRegistrationFormPlugin(initialData.plugins)
      return {
        title: showEnhancedSubmitSettingsPanel
          ? translations.t('submitSettingsPanel.formBuilder.panelTitle')
          : translations.t('buttonChangeText.panelTitle'),
        componentRef: initialData.componentRef,
        initialData,
        width: 288,
        height: showEnhancedSubmitSettingsPanel ? 525 : 100,
        url: urlFor('submit-settings'),
        helpId: '949303cc-13bf-4d95-ba5c-72d2db07b69e',
      }
    },
    [PanelName.ADD_FIELD]: (initialData: InitialPanelData<AddFieldOwnProps>) => ({
      title: translations.t('addField.panelTitle'),
      initialData,
      componentRef: initialData.componentRef,
      width: 430,
      height: 519,
      type: 'add' as any,
      url: urlFor('add-field'),
      helpId: '4681150c-affb-4fbf-a7a9-26fd45557cbf',
    }),
    [PanelName.UPGRADE_ALERT]: (initialData: InitialPanelData<UpgradeAlertPanelOwnProps>) => ({
      shouldHideHeader: true,
      initialData,
      componentRef: initialData.componentRef,
      ...sizesBySkin[PanelName.UPGRADE_ALERT][skin],
      url: urlFor('upgrade-alert'),
    }),
    [PanelName.PAYMENT_WIZARD]: (initialData: InitialPanelData<OwnPaymentWizardPanelProps>) => {
      return {
        shouldHideHeader: true,
        initialData,
        width: 744,
        height: 510,
        url: urlFor('payment-wizard'),
      }
    },
    [PanelName.CONTACT_SYNC_PANEL]: (initialData: InitialPanelData<OwnContactSyncPanelProps>) => ({
      title: translations.t('contactSync.panelTitle'),
      componentRef: initialData.componentRef,
      initialData,
      width: 504,
      height: 594,
      url: urlFor('contact-sync'),
    }),
    [PanelName.DATE_PICKER_PANEL]: (initialData: InitialPanelData<DatePickerPanelProps>) => ({
      title: translations.t('datePicker.panelTitle'),
      componentRef: initialData.componentRef,
      initialData,
      width: 300,
      height: 345,
      url: urlFor('date-picker'),
    }),
    [PanelName.ITEMS_SELECTOR_PANEL]: (
      initialData: InitialPanelData<ItemsSelectorPanelOwnProps>,
    ) => ({
      title: translations.t('complexPhone.countryCode.customList.panelTitle'),
      componentRef: initialData.componentRef,
      initialData,
      width: 288,
      height: 560,
      url: urlFor('items-selector'),
    }),
    [PanelName.COMPLEX_PHONE_LAYOUT]: (
      initialData: InitialPanelData<ComplexFieldLayoutPanelOwnProps>,
    ) => ({
      title: translations.t('complextPhoneLayoutForm.title'),
      componentRef: initialData.componentRef,
      initialData,
      width: 288,
      height: !initialData.hasLabel ? 205 : 371,
      url: urlFor('complex-phone-layout'),
      helpId: 'fc002f20-e3ef-47a9-8d7d-d4f1a9ad66e1',
    }),
    [PanelName.COMPLEX_PHONE_SETTINGS]: (initialData: InitialPanelData<any>) => ({
      title: translations.t('complexPhone.settings.title'),
      componentRef: initialData.componentRef,
      initialData,
      width: 288,
      height: 560,
      url: urlFor('complex-phone-settings'),
      helpId: '779577ad-08d2-442a-b7d2-1052ff48a996',
    }),
    [PanelName.COMPLEX_ADDRESS_LAYOUT]: (
      initialData: InitialPanelData<ComplexFieldLayoutPanelOwnProps>,
    ) => ({
      title: translations.t('complextAddressLayoutForm.title'),
      componentRef: initialData.componentRef,
      initialData,
      width: 288,
      height: !initialData.hasLabel ? 205 : 371,
      url: urlFor('complex-address-layout'),
      helpId: 'fc002f20-e3ef-47a9-8d7d-d4f1a9ad66e1',
    }),
    [PanelName.COMPLEX_ADDRESS_SETTINGS]: (initialData: InitialPanelData<any>) => ({
      title: translations.t('complextAddressSettings.title'),
      componentRef: initialData.componentRef,
      initialData,
      width: 288,
      height: 582,
      url: urlFor('complex-address-settings'),
      helpId: '779577ad-08d2-442a-b7d2-1052ff48a996',
    }),
  }
}

const sizesBySkin = {
  [PanelName.UPGRADE_ALERT]: {
    [ModalSkins.Classic]: { width: 564, height: 320 },
    [ModalSkins.NewWorkspace]: { width: 600, height: 472 },
    [ModalSkins.Responsive]: { width: 600, height: 444 },
  },
  [PanelName.ADD_FORM]: {
    [ModalSkins.Classic]: { width: 666, height: 563 },
    [ModalSkins.NewWorkspace]: { width: 666, height: 563 },
    [ModalSkins.Responsive]: { width: 520, height: 245 },
  },
  [PanelName.FORM_MANAGE_SUBSCRIBERS]: {
    [ModalSkins.Classic]: { width: 744, height: 488 },
    [ModalSkins.NewWorkspace]: { width: 744, height: 474 },
    [ModalSkins.Responsive]: { width: 744, height: 474 },
  },
  [PanelName.CONNECT_FIELD]: {
    [ModalSkins.Classic]: { width: 288, height: 440 },
    [ModalSkins.NewWorkspace]: { width: 288, height: 440 },
    [ModalSkins.Responsive]: { width: 288, height: 460 },
  },
}
