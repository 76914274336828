import { GeneralText } from '../../general-fields/definitions/general-text'
import { FormsFieldPreset } from '@wix/forms-common'

export class CrmText extends GeneralText {
  public get fieldType() {
    return FormsFieldPreset.CRM_TEXT
  }

  protected get crmLabel() {
    return this.translate(`fieldTypes.${FormsFieldPreset.GENERAL_TEXT}`)
  }

  protected get data(): any {
    return {
      textType: 'text',
    }
  }
}
