import { COUNTRIES_CODES } from '@wix/forms-common'
import { COMPLEX_PHONE_ROLES } from '../../../../../../constants/roles'
import { CountriesCodesKeys } from './constants'

export const getCountryCodeByGEO = (geo: CountriesCodesKeys) => COUNTRIES_CODES[geo]

export const getPhoneNumberField = (subFields: FormField[]): FormField => {
  return subFields.find((f: FormField) => f.role === COMPLEX_PHONE_ROLES.TEXT)
}

export const getCountryCodeField = (subFields: FormField[]): FormField => {
  return subFields.find((f: FormField) => f.role === COMPLEX_PHONE_ROLES.DROPDOWN)
}
