export const enum PAYMENT_STATUS {
  MISSING_PAYMENT_METHOD = 'missing-payment-method',
  CONNECTED = 'connected',
  GET_STARTED = 'get-started',
}

export const LEARN_MORE_HELP_ID = '8d53b9f5-d1eb-44ca-9441-e5c5be3865f5'

export const BI_ACTIONS = {
  OPEN_PAYMENT_OVERVIEW: 'Open Payment Overview',
  CHANGE_PAYMENT_METHOD: 'Change Payment Method',
  REMOVE_PAYMENT: 'Remove Payment from this site',
  REMOVE_PAYMENT_REMOVE: 'remove',
  REMOVE_PAYMENT_CANCEL: 'cancel',
  REMOVE_PAYMENT_DISMISS: 'dismiss',
}

export const DELETE_ALERT_HELP_ID = '25f55ee1-7d47-426d-a3d5-bd1567ac5b53'
