import { FIELDS, ROLE_FORM } from '../../../../../../constants/roles'
import { GeneralRadioGroup } from './general-radio-group'
import { FormsFieldPreset } from '@wix/forms-common'
import { GFPP, GFPP_ACTIONS, GFPP_IDS } from '../../../../manifests/manifest-commons'

// in use for payment form items list state (adding this field with the items the user given)
export class GeneralItemsList extends GeneralRadioGroup {
  public get role() {
    return FIELDS.ROLE_FIELD_ITEMS_LIST
  }

  public get fieldType() {
    return FormsFieldPreset.GENERAL_ITEMS_LIST
  }

  public get base() {
    return GeneralItemsList.prototype
  }

  public get manifest() {
    return {
      // TODO: add field manifest tests for this & custom amount field
      relatedToRole: ROLE_FORM,
      displayName: this.translate('manifest.paymentField.displayName'),
      behavior: {
        essential: {
          enabled: true,
          text: this.translate('manifest.paymentField.restoreText'),
        },
        duplicatable: false,
      },
      gfpp: {
        desktop: {
          // TODO: cover the whole payment field flow with sled test including gfpps existence
          helpId: '46c6c905-bf04-4de7-86b6-dd0d152be96a',
          mainAction1: {
            actionId: GFPP_IDS.NEW_FORM_SETTINGS,
            label: this.translate('manifest.paymentField.gfppTitle'),
          },
          mainAction2: {
            actionId: GFPP_IDS.DYNAMIC_FIELD_SETTINGS,
            label: this.translate('fieldSettings.gfppTitle'),
          },
          iconButtons: {
            [GFPP_ACTIONS.SETTINGS]: GFPP.REMOVE,
            [GFPP_ACTIONS.CONNECT]: GFPP.REMOVE,
            [GFPP_ACTIONS.CUSTOM_CONNECT]: {
              actionId: GFPP_IDS.CONNECT_FIELD,
            },
          },
        },
        mobile: {
          helpId: '46c6c905-bf04-4de7-86b6-dd0d152be96a',
        },
      },
    }
  }

  protected get props() {
    return {
      type: 'RadioGroupProperties',
      alignment: 'left',
      layout: 'vertical',
      buttonsMargin: 17,
      spacing: 16,
      buttonSize: 18,
      labelMargin: 24,
      required: true,
    } as any
  }

  protected get data() {
    return {
      type: 'RadioGroup',
      label: this.translate(`preset.${this.fieldType}Label`),
      options: [],
    } as any
  }
}
