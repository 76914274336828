import CoreApi from '../../core-api'
import _ from 'lodash'
import { undoable } from '../../decorators'
import { FormPlugin, LimitTypes } from '@wix/forms-common'
import { findPlugin, getPlugins, removePlugin, updatePlugin } from '../../plugins/utils'
import { ROLE_LIMIT_MESSAGE } from '../../../../constants/roles'
import translations from '../../../../utils/translations'
import { CommonStyles } from '../../services/form-style-service'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone' // import plugin
import utc from 'dayjs/plugin/utc' // import plugin
import customParseFormat from 'dayjs/plugin/customParseFormat' // import plugin

dayjs.extend(customParseFormat)
dayjs.extend(timezone)
dayjs.extend(utc)

export default class SubmissionLimitApi {
  private boundEditorSDK: BoundEditorSDK
  private coreApi: CoreApi
  private cachedLimitMessageByFormId

  constructor(boundEditorSDK, coreApi: CoreApi) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.cachedLimitMessageByFormId = {}
  }

  private _getCachedLimitMessage(formId: string) {
    return this.cachedLimitMessageByFormId[formId]
  }

  public setCachedLimitMessage(formId, limitMessage: string) {
    this.cachedLimitMessageByFormId[formId] = limitMessage
  }

  private _getLimitValue(limitType: LimitTypes, limitAmount: number, limitTime: LimitTime) {
    const valuesMapping = {
      [LimitTypes.AMOUNT]: limitAmount,
      [LimitTypes.TIME]: limitTime,
      [LimitTypes.NONE]: null,
    }

    return {
      savedLimitType: valuesMapping[limitType] ? limitType : LimitTypes.NONE,
      savedLimitValue: valuesMapping[limitType],
    }
  }

  private _extractLimitProps(limitType: LimitTypes, limitValue: LimitValue, timeZone: string) {
    if (limitType === LimitTypes.TIME) {
      const limitTime = limitValue as LimitTime

      const timezoneDate = dayjs.utc(limitTime.date).tz(timeZone)
      return {
        limitAmount: null,
        limitTime: {
          ...limitTime,
          hour: timezoneDate.format(limitTime.hourFormat === '12H' ? 'hh:mm A' : 'HH:mm'),
          date: new Date(timezoneDate.format('YYYY-MM-DD')),
        },
      }
    }
    return {
      limitAmount: (limitType === LimitTypes.AMOUNT ? limitValue : null) as number,
      limitTime: null,
    }
  }

  private async _setLimitSubmissionsPlugin(formComponentRef, limitType, limitAmount, limitTime) {
    let updatedPlugins
    const { savedLimitType, savedLimitValue } = this._getLimitValue(
      limitType,
      limitAmount,
      limitTime,
    )
    const componentConnection = await this.coreApi.getComponentConnection(formComponentRef)
    const plugins = getPlugins(componentConnection)
    const limitFormSubmissionsPlugin = findPlugin(plugins, FormPlugin.LIMIT_FORM_SUBMISSONS)

    const pluginShouldExists = limitType !== LimitTypes.NONE && savedLimitValue

    if (pluginShouldExists) {
      updatedPlugins = updatePlugin(plugins, {
        id: FormPlugin.LIMIT_FORM_SUBMISSONS,
        payload: {
          limitType: savedLimitType,
          limitValue: savedLimitValue,
        },
      })
    }

    if (!pluginShouldExists && limitFormSubmissionsPlugin) {
      updatedPlugins = removePlugin(plugins, FormPlugin.LIMIT_FORM_SUBMISSONS)
    }

    if (updatedPlugins) {
      await this.coreApi.setComponentConnection(
        formComponentRef,
        { plugins: updatedPlugins },
        false,
      )
    }
  }

  public loadSubmissionLimitTabData(componentRef, componentConnection) {
    return Promise.all([
      this.coreApi.settings.getMessage(componentRef, ROLE_LIMIT_MESSAGE),
      this.boundEditorSDK.document.info.getTimeZone(),
    ]).then((payload: any) => {
      const [limitMessage, timeZone] = payload

      const plugins = getPlugins(componentConnection)

      const limitSubmissionsPlugin = findPlugin(plugins, FormPlugin.LIMIT_FORM_SUBMISSONS)

      const { limitType, limitValue } = _.get(limitSubmissionsPlugin, 'payload', {})
      const { limitAmount, limitTime } = this._extractLimitProps(limitType, limitValue, timeZone)

      const limitMessageText =
        _.get(limitMessage, 'text') ||
        this._getCachedLimitMessage(componentRef.id) ||
        translations.t('settings.limitMessage.default')

      return {
        timeZone,
        limitType: limitType || LimitTypes.NONE,
        limitAmount,
        limitTime,
        limitMessage: limitMessageText,
      }
    })
  }

  @undoable()
  public async setLimitType(
    connectToRef: ComponentRef,
    limitType: LimitTypes,
    {
      limitTime,
      limitAmount,
      newMessage,
      commonStyles,
    }: {
      limitTime?: LimitTime
      limitAmount?: number
      newMessage?: string
      commonStyles?: CommonStyles
    } = {},
  ) {
    const isMultistep = await this.coreApi.isMultiStepForm(connectToRef)
    await this._setLimitSubmissionsPlugin(connectToRef, limitType, limitAmount, limitTime)

    if (limitType === LimitTypes.NONE) {
      if (isMultistep) {
        await this.coreApi.steps.removeLimitStep(connectToRef)
      } else {
        await this.coreApi.settings.removeMessage(connectToRef, { role: ROLE_LIMIT_MESSAGE })
      }
    } else {
      if (isMultistep) {
        await this.coreApi.steps.restoreLimitStep(connectToRef, newMessage)
      } else {
        await this.coreApi.fields.restoreLimitMessage(connectToRef, { commonStyles, newMessage })
      }
    }
  }

  @undoable()
  public setLimitValue(
    connectToRef: ComponentRef,
    limitType: LimitTypes,
    {
      limitTime,
      limitAmount,
    }: {
      limitTime?: LimitTime
      limitAmount?: number
    } = {},
  ) {
    return this._setLimitSubmissionsPlugin(connectToRef, limitType, limitAmount, limitTime)
  }

  public async removeLimitComponentWhenNotActive(formComponentRef: ComponentRef) {
    const componentConnection = await this.coreApi.getComponentConnection(formComponentRef)
    const plugins = getPlugins(componentConnection)
    const isMultistep = await this.coreApi.isMultiStepForm(formComponentRef)
    const limitFormSubmissionsPlugin = findPlugin(plugins, FormPlugin.LIMIT_FORM_SUBMISSONS)

    if (!limitFormSubmissionsPlugin) {
      if (isMultistep) {
        await this.coreApi.steps.removeLimitStep(formComponentRef)
      } else {
        const existingMessageRef = await this.coreApi.findComponentByRole(
          formComponentRef,
          ROLE_LIMIT_MESSAGE,
        )
        if (existingMessageRef) {
          await this.coreApi.settings.removeMessage(formComponentRef, { existingMessageRef })
        }
      }
    }
  }
}
