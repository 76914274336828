import _ from 'lodash'
import { getComponentTypeLayoutProp } from '../services/layout-service'
import {
  ChildLayoutMetaData,
  ContainerLayoutMetaData,
  getFieldRenderConfigFields,
} from '../preset/fields/field-types-data'
import { isInputField } from '../utils'

export type FieldLayoutProp = {
  [key: string]: PROPERTIES
} | null

export type FieldPropHandler = (fieldType: FieldPreset, value: PROPERTIES) => FieldLayoutProp | null
export type FieldDataHandler = FieldPropHandler

export const getTextAlignmentProp: FieldPropHandler = (fieldType, value) => {
  return getComponentTypeLayoutProp(fieldType, 'textAlignment', value)
}

export const getLabelMarginProp: FieldPropHandler = (fieldType, value) => {
  return getComponentTypeLayoutProp(fieldType, 'labelMargin', value)
}

export const getLabelPaddingProp: FieldPropHandler = (fieldType, value) => {
  return getComponentTypeLayoutProp(fieldType, 'labelPadding', value)
}

export const getInputTextPaddingProp: FieldPropHandler = (fieldType, value) => {
  return getComponentTypeLayoutProp(fieldType, 'textPadding', value)
}

const sortFieldsByRowsAndCols = (
  field: Partial<FormField>,
  fieldToMetadata,
): Partial<FormField>[] =>
  field.childFields.sort((first, second) => {
    const firstChildLayoutMetaData = fieldToMetadata[first.fieldType]
    const secondChildLayoutMetaData = fieldToMetadata[second.fieldType]
    return firstChildLayoutMetaData.row === secondChildLayoutMetaData.row
      ? firstChildLayoutMetaData.col < secondChildLayoutMetaData.col
        ? -1
        : 1
      : firstChildLayoutMetaData.row < secondChildLayoutMetaData.row
      ? -1
      : 1
  })

const toPercentage = (n: number): number => n / 100

const _orderFieldsByRowsAndCols = (
  field: Partial<FormField>,
  containerUpdate: FieldLayout,
  containerLayoutMetaData: ContainerLayoutMetaData,
  layoutsMetaData: ChildLayoutMetaData[],
): { componentRef: ComponentRef; layout: FieldLayout }[] => {
  const pixelsRowSpace = toPercentage(containerLayoutMetaData.rowSpace)
  const pixelsColSpace = toPercentage(containerLayoutMetaData.colSpace)
  const fieldHeight: number =
    (containerUpdate.height || field.height) /
    ((containerLayoutMetaData.rows - 1) * pixelsRowSpace || 1)
  const colSpace = pixelsColSpace * containerUpdate.width
  const fieldToMetadata = field.childFields.reduce(
    (acc, childField, index) => ({ ...acc, [childField.fieldType]: layoutsMetaData[index] }),
    {},
  )
  const sortedFields = sortFieldsByRowsAndCols(field, fieldToMetadata)

  const fieldUpdates = sortedFields.reduce<{ componentRef: ComponentRef; layout: FieldLayout }[]>(
    (currentUpdates, fieldToPlace, index) => {
      const layoutMetaData = fieldToMetadata[fieldToPlace.fieldType]
      const width = containerUpdate.width * toPercentage(layoutMetaData.width)
      const x =
        layoutMetaData.col > 0
          ? currentUpdates[index - 1].layout.x + currentUpdates[index - 1].layout.width + colSpace
          : 0
      const y = layoutMetaData.row * (fieldHeight + pixelsRowSpace)
      return [
        ...currentUpdates,
        {
          layout: { x, y, width, height: fieldHeight },
          componentRef: fieldToPlace.componentRef,
        },
      ]
    },
    [],
  )
  return fieldUpdates
}

export const orderFieldsByRowsAndCols = (
  field: Partial<FormField>,
  containerUpdate: FieldLayout,
): { componentRef: ComponentRef; layout: FieldLayout }[] => {
  const containerLayoutMetaData = getFieldRenderConfigFields([], field.fieldType)
    .layout as ContainerLayoutMetaData
  const layoutsMetaData = field.childFields.map(
    (childField) =>
      getFieldRenderConfigFields([], childField.fieldType).layout as ChildLayoutMetaData,
  )
  return _orderFieldsByRowsAndCols(field, containerUpdate, containerLayoutMetaData, layoutsMetaData)
}

const createWidgetUpdate = (
  { componentRef }: { componentRef: ComponentRef },
  layout: FieldLayout,
  extraProps = {},
) => {
  if (_.isEmpty(layout)) {
    return null
  }
  return {
    componentRef,
    layout,
    ...extraProps,
  }
}

export const getUpdatesForComplexField = async (
  complexField: FormField,
  widgetUpdate: { componentRef: any; layout: FieldLayout },
  reduceFunc: (
    x,
    y,
  ) => Promise<{
    updates: any[]
    startX: any
    x: any
    y: any
    width: number
    defaultWidth: any
    maxHeight: any
    currentCol: any
  }>,
  { width, colSize, defaultWidth },
) => {
  const childrenResponse = await _.reduce(complexField.childFields, reduceFunc, {
    updates: [],
    startX: 0,
    x: 0,
    y: 0,
    width,
    defaultWidth,
    maxHeight: 0,
    currentCol: colSize,
    firstInContainer: true,
  })
  const { layout } = widgetUpdate
  const newHeight = childrenResponse.y + _.last(complexField.childFields).height

  const diffHeight = newHeight - complexField.height
  const diffWidth = layout.width - complexField.width

  const layoutUpdate = {
    ...layout,
    height: newHeight,
  }

  const createLayout = (pred, extraProps = []) => {
    const predProps = _.compact([pred(diffHeight) && 'height', pred(diffWidth) && 'x'])
    return _.pick(layoutUpdate, ...extraProps, ...predProps)
  }

  const expandLayout = createLayout((d) => d > 0)
  const shrinkLayout = createLayout((d) => d < 0)
  const regularLayout = createLayout((d) => d === 0, ['y', 'width'])

  const widgetExpand = createWidgetUpdate(complexField, expandLayout, { expandContainer: true })
  const widgetShrink = createWidgetUpdate(complexField, shrinkLayout, { shrinkContainer: true })
  const widgetRegular = createWidgetUpdate(complexField, regularLayout)

  const WidgetUpdates = _.compact([widgetExpand, widgetShrink, widgetRegular])

  return {
    updates: [...childrenResponse.updates, ...WidgetUpdates],
    complexFieldHeight: newHeight,
  }
}

export const getFormWidth = (fields: FormField[], formWidth: number): number => {
  const inputs = fields.filter((field) => isInputField(field.role))
  if (!inputs.length) {
    return formWidth
  }
  const rightSpaces = inputs.map((field) => field.x)
  const leftSpaces = inputs.map((field) => field.x + field.width)

  const mostRightFieldSpace = Math.min(...rightSpaces)
  const mostLeftFieldSpace = formWidth - Math.max(...leftSpaces)

  return formWidth - mostRightFieldSpace - mostLeftFieldSpace
}
