import { IGeneralField } from '../general-field'
import { LoginEmail } from './definitions/login-email'
import { Password } from './definitions/password'
import { CheckboxAgreeTerms } from './definitions/checkbox-agree-terms'
import { CheckboxJoinCommunity } from './definitions/checkbox-join-community'
import { LinkToLogin } from './definitions/link-to-login'
import { PrivacyPolicy } from './definitions/privacy-policy'
import { CodeOfConduct } from './definitions/code-of-conduct'

export const createRegistrationFields = (): IGeneralField[] => [
  new LoginEmail(),
  new Password(),
  new LinkToLogin(),
  new CheckboxAgreeTerms(),
  new CheckboxJoinCommunity(),
  new PrivacyPolicy(),
  new CodeOfConduct(),
]
