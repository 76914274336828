import RemoteApi from '../../../panels/commons/remote-api'
import CoreApi from '../core-api'
import ClassicFieldSettingsApi from './classic'
import ResponsiveFieldSettingsApi from './responsive'

export const SPACE_BETWEEN_FIELDS = 32
export type FieldsApi = ClassicFieldSettingsApi | ResponsiveFieldSettingsApi

export default function (
  boundEditorSDK: BoundEditorSDK,
  coreApi: CoreApi,
  remoteApi: RemoteApi,
  helpers: { experiments; biLogger; ravenInstance; fedopsLogger },
) {
  switch (coreApi.editorType) {
    case 'RESPONSIVE':
      return new ResponsiveFieldSettingsApi(boundEditorSDK, coreApi, remoteApi, helpers)
    case 'ADI':
    default:
      return new ClassicFieldSettingsApi(boundEditorSDK, coreApi, remoteApi, helpers)
  }
}
