import { ExtendApi, ExtendApiCreator } from '../extend-api'
import translations from '../../../../utils/translations'
import CoreApi from '../../core-api'
import { EVENTS } from '../../../../constants/bi'
import { ACTION_TYPE } from '../action-types'
import { CUSTOM_ACTION_CLICKED } from '../event-types'
import { PanelName } from '../../../../constants/panel-names'

class MultiStepFormApi extends ExtendApiCreator {
  private coreApi: CoreApi

  constructor(coreApi: CoreApi) {
    super()
    this.coreApi = coreApi
  }

  get events(): { [event: string]: (payload: any) => any } {
    return {
      [CUSTOM_ACTION_CLICKED]: (eventPayload) => {
        const { action, formId, editorType, origin } = eventPayload

        switch (action.panelName) {
          case 'manageSteps':
            this.coreApi.log({
              evid: EVENTS.PANELS[PanelName.MANAGE_STEPS].OPEN_PANEL,
              form_comp_id: formId,
              click_type: origin,
              builderOrigin: editorType,
            })
            break
        }
      },
    }
  }

  getExtendApi(): ExtendApi {
    return {
      onEvent: this.createOnEvent(),
      appConfig: () => ({
        features: {
          layout: {
            chooseLayout: false,
          },
        },
        mainCTA: {
          actions: [
            {
              type: ACTION_TYPE.OPEN_COMPONENT_PANEL,
              panelName: 'manageSteps',
              text: translations.t('formSettings.mainTab.MultiStepForm.mainActionButtonText'),
            },
          ],
        },
      }),
    }
  }
}

export default MultiStepFormApi
