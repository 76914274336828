import { FormPlugin } from '@wix/forms-common'
import { AppStateBuilder } from '../app-state/app-state-builder'
import SubscribersApi from './basicApis/get-subscribers'
import RegistrationFormApi from './basicApis/registration-form'
import MultiStepFormApi from './basicApis/multistep-form'

import _ from 'lodash'
import GetSubscribersMultiStepFormApi from './multiplePluginsApis/get-subscribers-multistep-form'

const _defaultPluginAppState: PluginAppStateConfig = {
  plugins: [FormPlugin.FORM_BUILDER],
  apiExtenderBuilder: null,
  main: FormPlugin.FORM_BUILDER,
  displayNameKey: 'formBuilder',
  isDuplicatable: true,
}

const _pluginAppStatesConfigs: Partial<PluginAppStateConfig>[] = [
  _defaultPluginAppState,
  {
    main: FormPlugin.GET_SUBSCRIBERS,
    plugins: [FormPlugin.GET_SUBSCRIBERS],
    apiExtenderBuilder: SubscribersApi,
    displayNameKey: 'getSubscribersFormBuilder',
  },
  {
    main: FormPlugin.REGISTRATION_FORM,
    plugins: [FormPlugin.REGISTRATION_FORM],
    apiExtenderBuilder: RegistrationFormApi,
    isDuplicatable: false,
    displayNameKey: 'registrationFormBuilder',
  },
  {
    main: FormPlugin.MULTI_STEP_FORM,
    plugins: [FormPlugin.MULTI_STEP_FORM],
    apiExtenderBuilder: MultiStepFormApi,
  },
  {
    main: FormPlugin.GET_SUBSCRIBERS,
    plugins: [FormPlugin.GET_SUBSCRIBERS, FormPlugin.MULTI_STEP_FORM],
    apiExtenderBuilder: GetSubscribersMultiStepFormApi,
    displayNameKey: 'getSubscribersFormBuilder',
  },
]

export const pluginAppStatesConfigs: PluginAppStateConfig[] = _.map(
  _pluginAppStatesConfigs,
  (appState) => ({
    name: new AppStateBuilder({ plugins: appState.plugins }).toString(),
    ..._defaultPluginAppState,
    ...appState,
  }),
)

export const getPluginAppState = (plugins: FormPlugin[]): PluginAppStateConfig => {
  const [defaultState, ...restStates] = pluginAppStatesConfigs

  if (!plugins) {
    return defaultState
  }

  const stateConfig = _(restStates)
    .filter((config) => _.intersection(plugins, config.plugins).length === config.plugins.length)
    .maxBy((config) => config.plugins.length)

  return stateConfig || defaultState
}
