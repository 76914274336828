import 'regenerator-runtime/runtime'
import { fetcher } from '../../utils/utils'
import CoreApi from '../core/core-api'
import { LocaleData } from '@wix/forms-common'

class EditorAppMetaData {
  private coreApiResolver: DataResolver<CoreApi>
  private localeData: LocaleData
  public isInitialized: boolean
  public appManifest: any

  constructor() {
    this.isInitialized = false
    this.coreApiResolver = fetcher<CoreApi>()
  }

  getCoreApi(): Promise<CoreApi> {
    return this.coreApiResolver.getData
  }

  setCoreApi(coreApi: CoreApi): void {
    this.coreApiResolver.resolveData(coreApi)
  }

  setLocaleData(localeData: LocaleData) {
    this.localeData = localeData
  }

  getLocaleData(): LocaleData {
    return this.localeData
  }
}

export const editorAppMetaData = new EditorAppMetaData()
