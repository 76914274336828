import { IGeneralField } from '../../general-field'
import { ComplexPhoneWidget } from './definitions/complex-phone-widget'
import { ComplexPhoneDropdown } from './definitions/complex-phone-dropdown'
import { ComplexPhoneText } from './definitions/complex-phone-text'

export const createComplexPhoneField = (): IGeneralField[] => [
  new ComplexPhoneWidget(),
  new ComplexPhoneDropdown(),
  new ComplexPhoneText(),
]
