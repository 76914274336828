import { getFieldRenderConfigFields } from '../preset/fields/field-types-data'
import { FormPlugin, FieldRenderConfigType } from '@wix/forms-common'
import { CUSTOM_FIELD, CRM_TAGS } from '../../../constants/crm-types-tags'
import { SyncField, SyncData } from '../../../panels/contact-sync-panel/constants/types'
import _ from 'lodash'
import { CustomFieldResponse } from '../../../constants/field-types'
import { isCrmTypeAllowedOnce } from '../../../panels/contact-sync-panel/utils'
import { fieldsStore } from '../preset/fields/fields-store'

export const isFieldSyncable = (plugins: FormPlugin[], field: FormField) => {
  const fieldConfig = getFieldRenderConfigFields(plugins, field.fieldType)

  return fieldConfig.crmSync === FieldRenderConfigType.ENABLED
}

export const getSyncedFieldsCount = (
  fields: { crmType: string; customFieldKey: string }[],
): number =>
  fields.filter((field) => field.crmType !== CUSTOM_FIELD || !!field.customFieldKey).length

const getSyncDataFromField = (
  { crmType, crmTag, customFieldKey, customFieldName }: Partial<FormField>,
  customFields: CustomFieldResponse[],
): SyncData => {
  const fieldCustomFieldExistsInCustomFields: boolean = _.some(
    customFields,
    (_customField) => _customField.key === customFieldKey,
  )
  return _.defaults(
    _.pickBy(
      {
        crmType,
        crmTag:
          crmType === CUSTOM_FIELD
            ? undefined
            : crmTag || (isCrmTypeAllowedOnce(crmType) ? CRM_TAGS.MAIN : CRM_TAGS.OTHER),

        customFieldKey: fieldCustomFieldExistsInCustomFields ? customFieldKey : undefined,
        customFieldName: fieldCustomFieldExistsInCustomFields ? customFieldName : undefined,
      },
      (prop) => prop !== null,
    ),
    {
      crmType: undefined,
      crmTag: undefined,
      customFieldKey: undefined,
      customFieldName: undefined,
    },
  )
}

export const createSyncFieldFromFormField = (
  field: Partial<FormField>,
  customFields: CustomFieldResponse[],
): SyncField => {
  const fieldDefinition = fieldsStore.get(field.fieldType)
  return {
    componentRef: field.componentRef,
    customFields: fieldDefinition.customFields,
    fieldName: field.crmLabel,
    syncData: getSyncDataFromField(field, customFields),
    fieldType: field.fieldType,
  }
}

export const createSyncFieldsFromFormFields = (
  fieldsOnStage: FormField[],
  plugins: FormPlugin[],
  customFields: CustomFieldResponse[],
): SyncField[] =>
  fieldsOnStage
    .filter((field) => isFieldSyncable(plugins, field))
    .map((field) => createSyncFieldFromFormField(field, customFields))

export const mapComplexInnerFieldTypeToServerCrmType = {
  complexAddress: {
    complexAddressZipcode: 'zipCode',
    complexAddressCountry: 'country',
    complexAddressState: 'region',
    complexAddressCity: 'city',
    complexAddressStreet2: 'streetAddress2',
    complexAddressStreet: 'streetAddress',
  },
}
