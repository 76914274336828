import Experiments, { ExperimentsBag } from '@wix/wix-experiments'

class ExperimentsService {
  private _experiments: Experiments

  constructor() {}

  init(experiments: Experiments) {
    this._experiments = experiments
  }

  initByExperimentsBag(experimentsBag: ExperimentsBag) {
    this._experiments = new Experiments({ experiments: experimentsBag })
  }

  isEnabled(key: string): boolean {
    if (!this._experiments) {
      return false
    }

    return this._experiments.enabled(key)
  }
}

export default new ExperimentsService()
