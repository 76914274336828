import { FIELD_COMPONENT_TYPES } from '@wix/forms-common'
import _ from 'lodash'
import { baseStyleDefaults } from '../constants'
import { IGeneralField } from '../general-field'
import { CRM_TAGS } from '../../../../../constants/crm-types-tags'
import { FORMS_APP_DEF_ID } from '../../../../../constants'

export abstract class BaseComplexFieldWidget extends IGeneralField {
  public get crmTag() {
    return CRM_TAGS.OTHER
  }

  protected get componentType() {
    return FIELD_COMPONENT_TYPES.COMPLEX_FIELD_WIDGET
  }

  protected get data() {
    return {
      type: 'AppController',
      applicationId: FORMS_APP_DEF_ID,
    }
  }

  public get complexFieldWidget() {
    return true
  }

  public fieldStructure({
    isResponsive,
    itemLayoutType,
    layoutData,
  }: {
    isResponsive?: boolean
    itemLayoutType?: ResponsiveItemLayoutType
    layoutData?: ResponsiveLayoutData
  } = {}): Partial<RawComponentStructure> {
    const mediaContainerSkin = 'wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer'
    const mediaContainerCompType = 'wysiwyg.viewer.components.MediaContainer'
    const mediaContainerBaseStyle: ComponentStyle = _.assign({}, baseStyleDefaults, {
      componentClassName: mediaContainerCompType,
      skin: mediaContainerSkin,
    })

    const mediaContainerStyle: ComponentStyle = isResponsive
      ? {
          type: 'StylesInBreakpoint',
          skin: mediaContainerSkin,
          stylesInBreakpoints: [
            {
              ...mediaContainerBaseStyle,
              breakpoint: undefined,
            },
          ],
        }
      : mediaContainerBaseStyle

    const structure: Partial<RawComponentStructure> = {
      type: 'Container',
      componentType: 'platform.components.AppWidget',
      style: 'appWidget1',
      components: [
        {
          type: 'Container',
          componentType: mediaContainerCompType,
          skin: mediaContainerSkin,
          style: mediaContainerStyle,
          layout: {
            x: 0,
            y: 0,
            ...this.layout,
          },
          role: '',
          config: {},
        },
      ],
      label: this.crmLabel,
      ...this.properties.extraData,
    }

    if (isResponsive) {
      const height = _.get(structure, 'layout.height')
      structure.layoutResponsive = _.merge(
        {},
        this.baseResponsiveLayout({ height, itemLayoutType, layoutData }),
        this.layoutResponsive,
      )
    }

    return _.cloneDeep(structure)
  }
}
