import { FormsFieldPreset, AdiFieldPreset } from '@wix/forms-common'
import { Email } from '../../recommended-fields/definitions/email'
import { EMAIL_REGEX } from '../../../../../../constants/field-validation'

export class AdiEmail extends Email {
  public get fieldType() {
    return AdiFieldPreset.ADI_EMAIL
  }

  public get customFields() {
    return []
  }

  public get mainCrmTypes() {
    return []
  }

  protected get data() {
    return {
      placeholder: this.translate(`adi.fieldTypes.${FormsFieldPreset.EMAIL}.placeholder`),
      label: this.translate(`adi.fieldTypes.${FormsFieldPreset.EMAIL}.label`),
      textType: 'email',
      pattern: EMAIL_REGEX,
    }
  }

  protected get props() {
    return {
      required: true,
    }
  }

  protected get ADI_titleTranslationKey() {
    return `adi.${FormsFieldPreset.EMAIL}FieldEditPanel.title`
  }

  protected get crmLabel() {
    return this.translate(`fieldTypes.${FormsFieldPreset.EMAIL}`)
  }
}
