import _ from 'lodash'
import { RegistrationFieldPreset, FormPlugin, FieldRenderConfigType } from '@wix/forms-common'
import { FIELDS } from '../../../../../../constants/roles'
import { GeneralSingleCheckbox } from '../../general-fields/definitions/general-single-checkbox'
import { CATEGORIES } from '../../../../../../panels/manage-fields-panel/constants/manage-fields-constants'
import { fieldManifest } from '../../field-utils'

export class CheckboxAgreeTerms extends GeneralSingleCheckbox {
  public get customFields() {
    return []
  }

  protected get renderConfig(): any {
    return {
      [FormPlugin.REGISTRATION_FORM]: {
        crmSync: FieldRenderConfigType.REMOVE,
        connectPanelRegistrationFooter: FieldRenderConfigType.ENABLED,
        required: FieldRenderConfigType.DISABLED,
        internalName: FieldRenderConfigType.DISABLED,
        checkedByDefault: FieldRenderConfigType.DISABLED,
        addFieldPanelData: { category: CATEGORIES.registration },
      },
    }
  }

  public get allowOnlyOnce() {
    return true
  }

  public get disableCollectionSync() {
    return true
  }

  public get fieldType() {
    return RegistrationFieldPreset.REGISTRATION_FORM_CHECKBOX_AGREE_TERMS
  }

  public get role() {
    return FIELDS.ROLE_FIELD_REGISTRATION_FORM_CHECKBOX_AGREE_TERMS
  }

  protected get data() {
    return {
      label: this.translate(`preset.${_.camelCase(this.fieldType)}Label`),
    }
  }

  protected get props(): any {
    return {
      required: true,
    }
  }

  public get manifest() {
    return fieldManifest({
      displayName: this.translate('fields.labelName.checkbox'),
      registration: true,
    })
  }
}
