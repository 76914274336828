import { FIELDS, ROLE_FORM } from '../../../../../../constants/roles'
import { FormsFieldPreset } from '@wix/forms-common'
import { GeneralText } from './general-text'
import { GFPP_IDS, GFPP_ACTIONS, GFPP } from '../../../../manifests/manifest-commons'

// in use for payment form custom item (adding this field with the items the user given)
export class GeneralCustomAmount extends GeneralText {
  public get role() {
    return FIELDS.ROLE_FIELD_CUSTOM_AMOUNT
  }

  public get mainCrmTypes() {
    return []
  }

  public get fieldType() {
    return FormsFieldPreset.GENERAL_CUSTOM_AMOUNT
  }

  public get props() {
    return {
      required: true,
    }
  }

  public get data() {
    return {
      placeholder: this.translate(
        `fieldTypes.${FormsFieldPreset.GENERAL_CUSTOM_AMOUNT}.placeholder`,
      ),
    }
  }

  public get manifest() {
    return {
      relatedToRole: ROLE_FORM,
      displayName: this.translate('manifest.paymentField.displayName'),
      behavior: {
        essential: {
          enabled: true,
          text: this.translate('manifest.paymentField.restoreText'),
        },
        duplicatable: false,
      },
      gfpp: {
        desktop: {
          helpId: '96aaf208-7dc6-4f73-8cab-430429521d5d',
          mainAction1: {
            actionId: GFPP_IDS.NEW_FORM_SETTINGS,
            label: this.translate('manifest.paymentField.gfppTitle'),
          },
          mainAction2: {
            actionId: GFPP_IDS.DYNAMIC_FIELD_SETTINGS,
            label: this.translate('fieldSettings.gfppTitle'),
          },
          iconButtons: {
            [GFPP_ACTIONS.SETTINGS]: GFPP.REMOVE,
            [GFPP_ACTIONS.CONNECT]: GFPP.REMOVE,
            [GFPP_ACTIONS.CUSTOM_CONNECT]: {
              actionId: GFPP_IDS.CONNECT_FIELD,
            },
          },
        },
        mobile: {
          helpId: '96aaf208-7dc6-4f73-8cab-430429521d5d',
        },
      },
    }
  }
}
