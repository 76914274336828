import _ from 'lodash'
import { RegistrationFieldPreset, FormPlugin, FieldRenderConfigType } from '@wix/forms-common'
import { FIELDS } from '../../../../../../constants/roles'
import { CheckboxAgreeTerms } from './checkbox-agree-terms'
import { CATEGORIES } from '../../../../../../panels/manage-fields-panel/constants/manage-fields-constants'
import { fieldManifest } from '../../field-utils'

export class CheckboxJoinCommunity extends CheckboxAgreeTerms {
  protected get renderConfig() {
    return {
      [FormPlugin.REGISTRATION_FORM]: {
        crmSync: FieldRenderConfigType.REMOVE,
        connectPanelRegistrationFooter: FieldRenderConfigType.ENABLED,
        internalName: FieldRenderConfigType.DISABLED,
        required: FieldRenderConfigType.ENABLED,
        checkedByDefault: FieldRenderConfigType.ENABLED,
        addFieldPanelData: { category: CATEGORIES.registration },
      },
    }
  }

  public get disableCollectionSync() {
    return false
  }

  public get fieldType() {
    return RegistrationFieldPreset.REGISTRATION_FORM_CHECKBOX_JOIN_COMMUNITY
  }

  public get role() {
    return FIELDS.ROLE_FIELD_REGISTRATION_FORM_CHECKBOX_JOIN_COMMUNITY
  }

  protected get data() {
    return {
      label: this.translate(`preset.${_.camelCase(this.fieldType)}Label`),
    }
  }

  protected get props() {
    return {
      required: false,
    }
  }

  public get manifest() {
    return fieldManifest({ registration: true })
  }
}
