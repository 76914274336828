export const LinkTypes = {
  NONE: 'none',
  PAGE: 'page',
  LIGHTBOX: 'lightbox',
  EXTERNAL_LINK: 'ExternalLink',
  DOCUMENT_LINK: 'DocumentLink',
}

export const MediaTypes = {
  DOCUMENT: 'DOCUMENT',
  IMAGE: 'IMAGE',
}

export const SettingsTabs = {
  GENERAL: 'general',
  SUBMISSION: 'submission',
}

export const feedbackFooterHeight = 54
export const tabsHeight = 54
export const crucialElementHeight = 54
export const crucialElementsPadding = 18
