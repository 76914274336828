import { RegistrationFieldPreset, FormPlugin, FieldRenderConfigType } from '@wix/forms-common'
import { FIELDS } from '../../../../../../constants/roles'
import { CUSTOM_FIELD } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'
import { CATEGORIES } from '../../../../../../panels/manage-fields-panel/constants/manage-fields-constants'
import { TextInput } from '../../fields-by-comp-type/definitions/text-input'
import { fieldManifest } from '../../field-utils'

export class Password extends TextInput {
  public get customFields() {
    return []
  }

  public get allowOnlyOnce() {
    return true
  }

  public get acceptConditions() {
    return false
  }

  public get acceptOperations() {
    return false
  }

  public get disableCollectionSync() {
    return true
  }

  protected get icon() {
    return iconNames.password
  }

  protected get renderConfig() {
    return {
      [FormPlugin.REGISTRATION_FORM]: {
        isMandatory: true,
        crmSync: FieldRenderConfigType.REMOVE,
        connectPanelRegistrationFooter: FieldRenderConfigType.ENABLED,
        required: FieldRenderConfigType.DISABLED,
        internalName: FieldRenderConfigType.DISABLED,
        checkedByDefault: FieldRenderConfigType.DISABLED,
        addFieldPanelData: { category: CATEGORIES.registration },
      },
    }
  }

  public get fieldType() {
    return RegistrationFieldPreset.REGISTRATION_FORM_PASSWORD
  }

  protected get crmLabel() {
    return null
  }

  public get role() {
    return FIELDS.ROLE_FIELD_REGISTRATION_FORM_PASSWORD
  }

  public get crmType() {
    return CUSTOM_FIELD
  }

  protected get data(): any {
    return {
      textType: 'password',
      crmLabel: this.translate(`fieldTypes.${this.fieldType}`),
      placeholder: this.translate(`fieldTypes.${this.fieldType}`),
      maxLength: 256,
      type: 'TextInput',
      value: '',
    }
  }

  protected get props() {
    return {
      type: 'TextInputProperties',
      required: true,
    }
  }

  public get base() {
    return Password.prototype
  }

  public get manifest() {
    return fieldManifest({ registration: true })
  }
}
