import { IGeneralField } from '../general-field'
import { CrmText } from './definitions/crm-txt'
import { CrmNumber } from './definitions/crm-number'
import { CrmUrl } from './definitions/crm-url'
import { CrmDate } from './definitions/crm-date'

export const createCustomFields = (): IGeneralField[] => [
  new CrmText(),
  new CrmNumber(),
  new CrmUrl(),
  new CrmDate(),
]
