import { FORMS_APP_DEF_ID } from '../../../constants'

const defaultControllerType: ControllerType = 'wixForms'

export const APP_WIDGET_DEFINITION = {
  componentType: 'platform.components.AppWidget',
  style: 'appWidget1',
  data: {
    type: 'AppController',
    name: 'wix-forms-controller',
    applicationId: FORMS_APP_DEF_ID,
    controllerType: defaultControllerType,
  },
  type: 'Container',
}
