import { AdiFieldPreset } from '@wix/forms-common'
import { GeneralUploadButton } from '../../general-fields/definitions/general-upload-button'

export class AdiGeneralUploadButton extends GeneralUploadButton {
  public get fieldType() {
    return AdiFieldPreset.ADI_UPLOAD_BUTTON
  }

  protected get data() {
    return {
      label: this.translate(`adi.fieldTypes.${this.base.metadata.fieldType}.label`),
      buttonLabel: this.translate(`adi.fieldTypes.${this.base.metadata.fieldType}.placeholder`),
    }
  }

  protected get crmLabel() {
    return this.translate(`fieldTypes.${this.base.metadata.fieldType}`)
  }

  protected get ADI_titleTranslationKey() {
    return `adi.${this.base.metadata.fieldType}FieldEditPanel.title`
  }
}
