import { AdiFieldPreset } from '@wix/forms-common'
import { GeneralTextBox } from '../../general-fields/definitions/general-text-box'

export class AdiGeneralTextBox extends GeneralTextBox {
  public get fieldType() {
    return AdiFieldPreset.ADI_GENERAL_TEXT_BOX
  }

  protected get data() {
    return {
      placeholder: this.translate(`adi.fieldTypes.${this.base.metadata.fieldType}.placeholder`),
      label: this.translate(`adi.fieldTypes.${this.base.metadata.fieldType}.label`),
    }
  }

  protected get ADI_titleTranslationKey() {
    return `adi.${this.base.metadata.fieldType}FieldEditPanel.title`
  }

  protected get crmLabel() {
    return this.translate(`fieldTypes.${this.base.metadata.fieldType}`)
  }
}
