import { FormsFieldPreset, FormPlugin, FieldRenderConfigType } from '@wix/forms-common'
import { FormPreset } from '../../../../../../constants/form-types'
import { GeneralText } from '../../general-fields/definitions/general-text'
import { CRM_TYPES, CRM_TAGS } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'
import { CATEGORIES } from '../../../../../../panels/manage-fields-panel/constants/manage-fields-constants'
import { EMAIL_REGEX } from '../../../../../../constants/field-validation'
import { ServerFieldType } from '../../../../../../constants/field-types'

export class Email extends GeneralText {
  public get customFields() {
    return this.base.customFields
  }

  public get mainCrmTypes() {
    return [CRM_TYPES.EMAIL]
  }

  protected get icon() {
    return iconNames.email
  }

  public get fieldType(): FieldPreset {
    return FormsFieldPreset.EMAIL
  }

  public get serverType(): ServerFieldType {
    return ServerFieldType.EMAIL
  }

  public get crmType() {
    return CRM_TYPES.EMAIL
  }

  public get crmTag() {
    return CRM_TAGS.OTHER
  }

  protected get data(): any {
    return {
      textType: 'email',
      pattern: EMAIL_REGEX,
      placeholder: this.translate(`fieldTypes.${this.fieldType}`),
      type: 'TextInput',
      value: '',
    }
  }

  protected get renderConfig() {
    return {
      [FormPlugin.FORM_BUILDER]: {
        addFieldPanelData: {
          category: CATEGORIES.contact,
        },
      },
      [FormPreset.GET_SUBSCRIBERS]: {
        required: FieldRenderConfigType.DISABLED,
        isMandatory: true,
      },
    }
  }

  protected get props(): any {
    return {
      required: true,
      type: 'TextInputProperties',
    }
  }

  public get subRole() {
    return `subRole_${this.data.textType}`
  }

  public get subManifest() {
    return { displayName: this.translate('fields.labelName.emailField') }
  }
}
