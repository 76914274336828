import { ConvertEmailRequest, WixFormBuilderWeb } from '@wix/ambassador-wix-form-builder-web/http'
import {
  getForm,
  getFormByExternalId,
  queryForms,
  updateForm,
} from '@wix/ambassador-forms-v2-form/http'
import { UpdateFormResponse } from '@wix/ambassador-forms-v2-form/types'
import {
  AddFormIdMappingRequest,
  FormEmailSettings,
  FormSummary,
  PublishSiteRequest,
} from '@wix/ambassador-wix-form-builder-web/types'
import _ from 'lodash'
import { FORMS_APP_DEF_ID } from '../../constants'
import { withFedops } from '../../editor-app/core/decorators'
import { FORMS_SERVER } from './constants/external-endpoints'

export class FormsServiceAmbassadorClient {
  private callerId = { 'X-Wix-Client-Artifact-Id': 'wix-form-builder' }
  private httpClient: IHttpClient

  constructor(
    private boundEditorSDK: BoundEditorSDK,
    private fedopsLogger,
    httpClient: IHttpClient,
  ) {
    this.httpClient = httpClient
  }

  private async _getAppInstance() {
    return this.boundEditorSDK.info.getAppInstance()
  }

  private _getBaseServicesClient(baseUrl: string = FORMS_SERVER.BASE_URL) {
    return WixFormBuilderWeb(baseUrl, { ignoredProtoHttpUrlPart: '/api' })
  }

  private async _getRequestHeader() {
    return { Authorization: await this._getAppInstance(), ...this.callerId }
  }

  public async getEmailsByEmailIds(emailIds: string[]) {
    return this._getBaseServicesClient()
      .EmailService()(await this._getRequestHeader()) // TODO: add FQDN
      .getEmails({
        emailIds: _.compact(emailIds),
      })
  }

  public async getSiteUsers() {
    return this._getBaseServicesClient()
      .EmailService()(await this._getRequestHeader()) // TODO: add FQDN
      .listSiteUsersEmail({})
  }

  public async addEmail(email: string) {
    return this._getBaseServicesClient()
      .EmailService()(await this._getRequestHeader()) // TODO: add FQDN
      .addEmail({ email })
  }

  public publishSite = async (data: PublishSiteRequest) => {
    return this._getBaseServicesClient()
      .FormBuilderService()(await this._getRequestHeader()) // TODO: add FQDN
      .publishSite(data)
  }

  public addContactFormMapping = async (data: AddFormIdMappingRequest) => {
    return this._getBaseServicesClient()
      .ContactFormService()(await this._getRequestHeader()) // TODO: add FQDN
      .addFormIdMapping(data)
  }

  public async editDraft(form: FormSummary) {
    return this._getBaseServicesClient()
      .FormBuilderService()(await this._getRequestHeader()) // TODO: add FQDN
      .editDraft({ form })
  }

  public async convertContactFormEmails(convertContactFormEmailsRequest: ConvertEmailRequest) {
    return this._getBaseServicesClient()
      .ContactFormService()(await this._getRequestHeader()) // TODO: add FQDN
      .convertEmail(convertContactFormEmailsRequest)
  }

  public getForm(formEditorId: string) {
    return this.httpClient
      .request(getFormByExternalId({ externalId: formEditorId }))
      .then(({ data: res }) => res.form)
  }

  public getFormByGuid(formId: string) {
    return this.httpClient.request(getForm({ formId })).then(({ data: res }) => res.form)
  }

  private _updateFormName = async ({
    publicId,
    name,
    revision,
  }: {
    publicId: string
    name: string
    revision: number
  }) => {
    return this.httpClient
      .request(
        updateForm({
          formId: publicId,
          revision,
          info: { name },
          fieldMask: ['name'],
        }),
      )
      .then(({ data }) => data)
  }

  private _updateEmailSettings = async ({
    publicId,
    emailSettings,
    inboxNotificationOptOut,
    revision,
  }: {
    publicId: string
    emailSettings: FormEmailSettings
    inboxNotificationOptOut: boolean
    revision: number
  }) => {
    return this.httpClient
      .request(
        updateForm({
          revision,
          formId: publicId,
          info: {
            attributes: {
              afterSubmitSettings: {
                inboxNotificationOptOut: {
                  enabled: inboxNotificationOptOut,
                },
              },
            },
            formMetadata: {
              emailSettings,
            },
          },

          fieldMask: [
            'formMetadata.emailSettings',
            'attributes.afterSubmitSettings.inboxNotificationOptOut',
          ],
        }),
      )
      .then(({ data }) => data)
  }

  private _generalUpdate(
    update_fn: (args) => Promise<UpdateFormResponse>,
    args: { publicId: string; revision: number },
  ) {
    return update_fn(args).catch(async (e) => {
      if (e.statusCode === 428) {
        const currentForm = await this.getFormByGuid(args.publicId)
        return update_fn({ ...args, revision: _.toNumber(currentForm.revision) })
      }
      throw e
    })
  }

  public updateEmailSettings(args: {
    publicId: string
    emailSettings: FormEmailSettings
    inboxNotificationOptOut: boolean
    revision: number
  }) {
    return this._generalUpdate(this._updateEmailSettings, args)
  }

  public updateFormName(args: { publicId: string; name: string; revision: number }) {
    return this._generalUpdate(this._updateFormName, args)
  }

  @withFedops('get-form-names')
  public async getSimilarFormNames({ name }: { name: string }): Promise<string[]> {
    return this.httpClient
      .request(
        queryForms({
          query: {
            filter: {
              $and: [
                {
                  name: { $contains: name },
                },
                {
                  app_id: FORMS_APP_DEF_ID,
                },
                {
                  status: {
                    $in: ['DRAFT', 'PUBLISHED'],
                  },
                },
              ],
            },
            fields: [],
            sort: [],
            fieldsets: [],
            paging: { offset: 0, limit: 100 },
          },
          fieldMask: ['name'],
        }),
      )
      .then(({ data: res }) => (res.forms ? res.forms.map((form) => form.name) : []))
  }

  @withFedops('get-other-form-names')
  public async getOtherFormNames({ publicId }: { publicId: string }): Promise<string[]> {
    return this.httpClient
      .request(
        queryForms({
          query: {
            filter: {
              $and: [
                {
                  app_id: FORMS_APP_DEF_ID,
                },
                {
                  status: {
                    $in: ['DRAFT', 'PUBLISHED'],
                  },
                },
                {
                  id: {
                    $ne: publicId,
                  },
                },
              ],
            },
            fields: [],
            sort: [],
            fieldsets: [],
            paging: { offset: 0, limit: 100 },
          },
          fieldMask: ['name'],
        }),
      )
      .then(({ data: res }) => (res.forms ? res.forms.map((form) => form.name) : []))
  }
}
