import _ from 'lodash'
import {
  COMPONENT_TYPES,
  FormsFieldPreset,
  FormPlugin,
  SuccessActionTypes,
} from '@wix/forms-common'
import { FormPreset } from '../../../constants/form-types'
import { ROLE_FORM, ROLE_MESSAGE, FIELDS_ROLES, FIELDS } from '../../../constants/roles'
import successMessageStructure from '../../../assets/presets/hidden-message.json'
import submitButtonStructure from '../../../assets/presets/submit-button.json'
import { translateContactFormStyle } from './contact-form-style'
import { fieldsStore } from '../preset/fields/fields-store'
import { createSuffixedName } from '../../../utils/utils'
import { enhanceFormWithMobileStructure } from './contact-form-mobile-service'
import { enhanceFormWithDesktopStructure } from './contact-form-layout'
import translations from '../../../utils/translations'
import { FontOption } from '@wix/platform-editor-sdk'
import sideInputLabel from '../../../assets/presets/side-input-label.json'
import { baseStylableButton, isStylableButton } from './stylable-button-service'

export const getTextInputComponent = (convertedForm: RawComponentStructure) =>
  _.find(convertedForm.components, {
    componentType: COMPONENT_TYPES.TEXT_INPUT,
  })
export const getButtonComponent = (convertedForm: RawComponentStructure) =>
  _.find(convertedForm.components, {
    componentType: COMPONENT_TYPES.SITE_BUTTON,
  })

export type DynamicContactFormEnhancer = (
  contactForm: DynamicContactForm,
  convertedForm: RawComponentStructure,
  { mobileLayouts, desktopLayouts, payload }: { mobileLayouts; desktopLayouts; payload? },
  fontOptions?: FontOption[],
  colorMap?: ColorPalette,
) => RawComponentStructure

export type ContactFormEnhancer = (
  contactForm: ContactForm,
  convertedForm: RawComponentStructure,
  { mobileLayouts, desktopLayouts, payload }: { mobileLayouts; desktopLayouts; payload? },
  fontOptions?: FontOption[],
  colorMap?: ColorPalette,
) => RawComponentStructure

export type ContactFormFieldEnhancer = (
  contactForm: ContactForm,
  dynamicField: ContactFormField,
  convertedField: RawComponentStructure,
  payload: any,
  skipRoles: string[],
) => RawComponentStructure

export const isSideLabelSkin = (skin: string) =>
  [
    'wysiwyg.viewer.skins.contactform.VerticalFormLabelsLeft',
    'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormSkin',
    'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormSkinTopLabels',
  ].includes(skin)
export const isSkinWithFieldTitles = (skin: string) =>
  skin === 'wysiwyg.viewer.skins.contactform.VerticalForm'
export const createBaseStructure = (contactForm: ContactForm): RawComponentStructure => ({
  type: 'Container',
  skin: 'wysiwyg.viewer.skins.FormContainerSkin',
  layout: {
    x: contactForm.layout.x,
    y: contactForm.layout.y,
    width: contactForm.layout.width,
    height: contactForm.layout.height,
  },
  componentType: COMPONENT_TYPES.FORM_CONTAINER,
  style: {
    type: 'ComponentStyle',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
    },
    style: {
      properties: {
        bg: '#FFFFFF',
        'alpha-bg': 0,
      },
      propertiesSource: {
        bg: 'value',
      },
    },
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.FormContainerSkin',
  },
  role: ROLE_FORM,
  config: {
    labels: ['contacts-contacted_me'],
    labelKeys: ['contacts.contacted-me'],
    errorMessage: 'Fill in missing fields.',
    secondsToResetForm: 8,
  },
})

export const createSubmitButtonComponent = (
  contactForm: ContactForm,
  stylable: boolean = false,
): RawComponentStructure => {
  return _.merge({}, stylable ? baseStylableButton : submitButtonStructure, {
    layout: {
      width: 142,
      height: 40,
      x: 60,
      y: 52,
    },
    data: {
      label: contactForm.data.submitButtonLabel || 'Send',
    },
  })
}

export const skinWithCenterMessages = [
  'contactform.FullWidthButtonSkin',
  'contactform.LineOnlySkin',
  'contactform.OverlappingButtonSkin',
  'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutFlat',
  'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutFlat',
  'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutTransparentWithIcon',
  'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutShiny',
  'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutEnvelope',
]

const createSuccessMessageComponent = (contactForm: ContactForm): RawComponentStructure => {
  return _.merge({}, successMessageStructure, {
    layout: {
      width: contactForm.layout.width - 60,
      height: 21,
      x: 60,
      y: 102,
    },
    data: {
      text: `<p class="font_8"><span style="color:#8FCA8F;">${
        contactForm.data.successMessage || translations.t('contactForm.defaultSuccessMessage')
      }</span></p>`,
    },
  })
}

export const convertContactFormToWixForms = (
  contactForm: DynamicContactForm,
  fontOptions,
  colorMap: ColorPalette,
  { mobileLayouts, desktopLayouts, payload }: { mobileLayouts; desktopLayouts; payload? },
): RawComponentStructure => {
  const dynamicFields = fetchDynamicFields(contactForm, payload)
  const buttonPadding =
    getElementWithPadding(desktopLayouts) || getElementWithPadding(mobileLayouts)
  const components: RawComponentStructure[] = [
    ...dynamicFields,
    createSubmitButtonComponent(contactForm, !!buttonPadding),
    createSuccessMessageComponent(contactForm),
  ]
  const baseStructure = _.merge({}, createBaseStructure(contactForm), {
    components,
    config: {
      preset: FormPreset.CONTACT_FORM,
      formName: contactForm.data.formName || 'Contact',
      plugins: [
        {
          id: FormPlugin.FORM_BUILDER,
        },
      ],
    },
  })

  const enhancers: (ContactFormEnhancer | DynamicContactFormEnhancer)[] = [
    fixSideLabelSkin,
    behaviorsEnhancer,
    emailEnhancer,
    handleAfterSubmitBehavior,
    hideFormInMobile,
    enhanceFormWithMobileStructure,
    enhanceFormWithDesktopStructure,
    enhanceWithStyle,
    modesEnhancer,
    fixDuplicatedCrmLabels,
  ]
  const convertedStructure = enhancers.reduce<RawComponentStructure>(
    (previousStructure, enhancer) =>
      enhancer(
        contactForm,
        previousStructure,
        { desktopLayouts, mobileLayouts, payload },
        fontOptions,
        colorMap,
      ),
    baseStructure,
  )
  return convertedStructure
}

export const fixSideLabelSkin = (
  contactForm: DynamicContactForm,
  convertedForm: RawComponentStructure,
) => {
  if (!isSideLabelSkin(contactForm.skin)) {
    return convertedForm
  }
  return {
    ...convertedForm,
    components: convertedForm.components.reduce((currComponents, component) => {
      if (_.includes(FIELDS_ROLES, component.role)) {
        if (component.role === FIELDS.ROLE_FIELD_COMPLEX_PHONE_WIDGET) {
          const {
            components: [container],
          } = component
          const {
            components: [dropdown, input],
          } = container
          const labelText = input.data.placeholder
          const phoneComponent = {
            ...component,
            components: [
              {
                ...container,
                components: [
                  dropdown,
                  _.merge({}, input, {
                    data: { placeholder: '' },
                    props: { placeholder: '' },
                  }),
                ],
              },
            ],
          }

          const labelComponent = _.merge({}, sideInputLabel, {
            data: {
              text: `<p class="font_8"><span style="color:#000000;">${labelText}</span></p>`,
            },
          })
          return [...currComponents, labelComponent, phoneComponent]
        } else {
          const labelText = component.data.placeholder
          const inputComponent = _.merge({}, component, {
            data: { placeholder: '' },
            props: { placeholder: '' },
          })
          const labelComponent = _.merge({}, sideInputLabel, {
            data: {
              text: `<p class="font_8"><span style="color:#000000;">${labelText}</span></p>`,
            },
          })
          return [...currComponents, labelComponent, inputComponent]
        }
      } else {
        return [...currComponents, component]
      }
    }, []),
  }
}

const createBaseField = (contactFormField: DynamicContactFormField) => {
  switch (contactFormField.name) {
    case 'name':
      return fieldsStore.get(FormsFieldPreset.FIRST_NAME)
    case 'address':
      return fieldsStore.get(FormsFieldPreset.ADDRESS)
    case 'phone':
      return fieldsStore.get(FormsFieldPreset.PHONE)
    case 'email':
      return fieldsStore.get(FormsFieldPreset.MAIN_EMAIL)
    case 'secondaryEmail':
      return fieldsStore.get(FormsFieldPreset.EMAIL)
    case 'message':
      return fieldsStore.get(FormsFieldPreset.GENERAL_TEXT_BOX)
    default:
      return fieldsStore.get(FormsFieldPreset.GENERAL_TEXT)
  }
}

const createBaseFieldHorizontal = (contactFormField: DynamicContactFormField) => {
  switch (contactFormField.name) {
    case 'message':
      return fieldsStore.get(FormsFieldPreset.GENERAL_TEXT)
    default:
      return createBaseField(contactFormField)
  }
}
const createBaseFieldPerSkin = {
  'wysiwyg.viewer.skins.contactform.HorizontalContactFormSkin': createBaseFieldHorizontal,
}

export const wrapEnhancerWithRoles = (
  convertedField: Partial<RawComponentStructure>,
  skipRoles: string[],
  action: (field: Partial<RawComponentStructure>) => RawComponentStructure,
): RawComponentStructure => {
  const role = convertedField.role || _.get(convertedField, 'connections.items[0].role')
  const shouldEnhanceComponent = !skipRoles.includes(role)

  const enhancedField = shouldEnhanceComponent ? action(convertedField) : convertedField
  if (convertedField.components) {
    enhancedField.components = convertedField.components.map((component) =>
      wrapEnhancerWithRoles(component, skipRoles, action),
    )
  }
  return enhancedField as RawComponentStructure
}

export const updateConfig: ContactFormFieldEnhancer = (
  _contactForm: DynamicContactForm,
  _dynamicField: DynamicContactFormField,
  convertedField: RawComponentStructure,
  _payload,
  skipRoles = [],
): RawComponentStructure => {
  const action = (field: Partial<RawComponentStructure>) => {
    field.config = { ...(field as any).connectionConfig }
    delete (field as any).connectionConfig
    return field as RawComponentStructure
  }
  return wrapEnhancerWithRoles(convertedField, skipRoles, action)
}

export const updateCrmLabel: ContactFormFieldEnhancer = (
  _contactForm: DynamicContactForm,
  dynamicField: DynamicContactFormField,
  convertedField: RawComponentStructure,
  _payload,
  skipRoles = [],
): RawComponentStructure => {
  const action = (field: Partial<RawComponentStructure>) => {
    field.config.crmLabel = dynamicField.displayLabel?.substring(0, 25).trim() || dynamicField.name
    return field as RawComponentStructure
  }
  return wrapEnhancerWithRoles(convertedField, skipRoles, action)
}

const updateLabel: ContactFormFieldEnhancer = (
  contactForm: DynamicContactForm,
  dynamicField: DynamicContactFormField,
  convertedField: RawComponentStructure,
): RawComponentStructure => {
  if (isSkinWithFieldTitles(contactForm.skin)) {
    convertedField.data.placeholder = ''
    convertedField.props.placeholder = ''
    convertedField.data.label = dynamicField.displayLabel || ''
  }

  return convertedField
}

export const updatePlaceholder: ContactFormFieldEnhancer = (
  contactForm: DynamicContactForm,
  dynamicField: DynamicContactFormField,
  convertedField: RawComponentStructure,
): RawComponentStructure => {
  const content = `${dynamicField.displayLabel || ''}${dynamicField.required ? ' *' : ''}`
  if (!isSkinWithFieldTitles(contactForm.skin)) {
    convertedField.data.label = ''
    convertedField.data.placeholder = content
    convertedField.props.placeholder = content
  }

  return convertedField
}

export const updateRequired: ContactFormFieldEnhancer = (
  _contactForm: DynamicContactForm,
  dynamicField: DynamicContactFormField,
  convertedField: RawComponentStructure,
  _payload,
  skipRoles = [],
): RawComponentStructure => {
  const action = (field: Partial<RawComponentStructure>) => {
    field.props.required = dynamicField.required
    return field as RawComponentStructure
  }
  return wrapEnhancerWithRoles(convertedField, skipRoles, action)
}

export const updateTextAlignment: ContactFormFieldEnhancer = (
  contactForm: DynamicContactForm,
  _dynamicField: DynamicContactFormField,
  convertedField: RawComponentStructure,
  _payload,
  skipRoles = [],
): RawComponentStructure => {
  const action = (field: Partial<RawComponentStructure>) => {
    field.props.textAlignment = contactForm.data.textDirection
    return field as RawComponentStructure
  }
  return wrapEnhancerWithRoles(convertedField, skipRoles, action)
}

export const fixDuplicatedCrmLabels: ContactFormEnhancer = (
  _contactForm: DynamicContactForm,
  convertedForm: RawComponentStructure,
) => {
  const crmLabels = []

  _.forEach(convertedForm.components, (field) => {
    if (!field.config.crmLabel) {
      return
    }

    if (_.includes(crmLabels, field.config.crmLabel)) {
      field.config.crmLabel = createSuffixedName(crmLabels, field.config.crmLabel)
    }

    crmLabels.push(field.config.crmLabel)
  })

  return convertedForm
}

const paddingEnhancer: ContactFormFieldEnhancer = (contactForm, _field, convertedField) => {
  if (contactForm.skin === 'contactform.OverlappingButtonSkin') {
    return _.merge({}, convertedField, { props: { textPadding: 10 } })
  }
  return _.merge({}, convertedField, { props: { textPadding: 5 } })
}

const fieldEnhancers: ContactFormFieldEnhancer[] = [
  paddingEnhancer,
  updateConfig,
  updateCrmLabel,
  updateLabel,
  updatePlaceholder,
  updateRequired,
  updateTextAlignment,
]

const fetchDynamicFields = (contactForm: DynamicContactForm, payload) => {
  return _.map(contactForm.data.dynamicFields, (field) => {
    const skinBaseField = createBaseFieldPerSkin[contactForm.skin] || createBaseField
    const baseField = skinBaseField(field).fieldStructure() as RawComponentStructure

    const convertedFieldStructure = fieldEnhancers.reduce<RawComponentStructure>(
      (previousFieldStructure, enhancer) =>
        enhancer(contactForm, field, previousFieldStructure, payload, []),
      baseField,
    )

    return convertedFieldStructure
  })
}

export const hideFormInMobile: ContactFormEnhancer = (
  contactForm: ContactForm,
  struct: RawComponentStructure,
): RawComponentStructure => {
  if (!_.get(contactForm.mobileHints, 'hidden')) {
    return struct
  }
  const hideFormInMobileAction: (
    structure: Partial<RawComponentStructure>,
  ) => RawComponentStructure = (structure) => {
    const structureWithMobileHidden: RawComponentStructure = {
      ...structure,
      mobileHints: { hidden: true, type: 'MobileHints' },
    } as RawComponentStructure
    if (structure.components) {
      return {
        ...structureWithMobileHidden,
        components: structureWithMobileHidden.components.map((component) =>
          hideFormInMobileAction(component),
        ),
      }
    } else {
      return structureWithMobileHidden
    }
  }
  return hideFormInMobileAction(struct)
}

export const handleAfterSubmitBehavior: ContactFormEnhancer = (
  contactForm: ContactForm,
  structure: RawComponentStructure,
): RawComponentStructure => {
  const shouldShowLink = contactForm.data.onSubmitBehavior === 'link'
  const enhancedStructure = _.merge({}, structure, {
    config: {
      successActionType: shouldShowLink ? SuccessActionTypes.LINK : SuccessActionTypes.SHOW_MESSAGE,
      successLinkValue: shouldShowLink ? contactForm.data.link : undefined,
    },
  })
  if (shouldShowLink) {
    enhancedStructure.components = structure.components.filter(
      (component) => component.role !== ROLE_MESSAGE,
    )
  }
  return enhancedStructure
}

export const emailEnhancer: ContactFormEnhancer = (
  contactForm: ContactForm,
  structure: RawComponentStructure,
  { payload },
): RawComponentStructure => {
  return {
    ...structure,
    config: {
      ...structure.config,
      ...(_.get(payload, 'shouldOptOut') ? { inboxOptOut: true } : {}),
      emailId: '',
      emailIds: [contactForm.data.toEmailAddress, contactForm.data.bccEmailAddress].filter(
        (email) => email && email.replace(/\s+/g, ''),
      ),
    },
  }
}

export const behaviorsEnhancer: ContactFormEnhancer = (
  contactForm: ContactForm,
  structure: RawComponentStructure,
): RawComponentStructure => {
  return {
    ...structure,
    behaviors: contactForm.behaviors,
  }
}

export const modesEnhancer: ContactFormEnhancer = (
  contactForm: ContactForm,
  structure: RawComponentStructure,
): RawComponentStructure => {
  return {
    ...structure,
    modes: contactForm.modes
      ? contactForm.modes.overrides
        ? {
            ...contactForm.modes,
            overrides: contactForm.modes.overrides.map((override) => ({
              ...override,
              style: structure.style,
            })),
          }
        : contactForm.modes
      : undefined,
    activeModes: contactForm.activeModes,
  }
}

export const enhanceWithStyle: ContactFormEnhancer = (
  contactForm: ContactForm,
  struct: RawComponentStructure,
  { payload, desktopLayouts, mobileLayouts },
  fontOptions: FontOption[],
  colorMap: ColorPalette,
): RawComponentStructure => {
  const enhanceWithStyleAction: (structure: RawComponentStructure) => RawComponentStructure = (
    structure,
  ) => {
    const componentWithStyle: RawComponentStructure = translateContactFormStyle(
      contactForm,
      structure,
      fontOptions,
      colorMap,
      isStylableButton(structure)
        ? _.get(
            getElementWithPadding(desktopLayouts) || getElementWithPadding(mobileLayouts),
            'horizontalPadding',
          )
        : undefined,
      payload,
    )
    if (structure.components) {
      return {
        ...componentWithStyle,
        components: componentWithStyle.components.map((component) =>
          enhanceWithStyleAction(component as RawComponentStructure),
        ),
      }
    } else {
      return componentWithStyle
    }
  }
  return enhanceWithStyleAction(struct)
}

export const getElementWithPadding = (layouts) =>
  _.find(layouts, (layout) => _.has(layout, 'horizontalPadding'))
