import { FormsFieldPreset, FieldCollectionType } from '@wix/forms-common'
import { GeneralText } from '../../../general-fields/definitions/general-text'
import { COMPLEX_ADDRESS_ROLES } from '../../../../../../../constants/roles'

export class ComplexAddressState extends GeneralText {
  public get fieldType(): FieldPreset {
    return FormsFieldPreset.COMPLEX_ADDRESS_STATE
  }

  public get role() {
    return COMPLEX_ADDRESS_ROLES.STATE
  }

  protected get crmLabel() {
    return this.translate(`fieldTypes.${FormsFieldPreset.COMPLEX_ADDRESS_STATE}`)
  }

  public get collectionFieldType() {
    return FieldCollectionType.TEXT
  }

  protected get data() {
    return {
      textType: 'text',
      placeholder: this.translate('complexAddress.state.defaultTitle'),
      type: 'TextInput',
      value: '',
    }
  }

  protected get mobileHints() {
    return {
      recommendedWidth: 260,
    }
  }
}
