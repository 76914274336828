import _ from 'lodash'
import CoreApi from '../core-api'
import DefaultFormApi from './default-form'
import { FormsExtendApi } from './extend-api'
import { getPluginAppState, pluginAppStatesConfigs } from './pluginAppStates'

export default class PluginsApi {
  private pluginsApi: { [key: string]: any }
  private defaultFormApi: FormsExtendApi

  constructor(coreApi: CoreApi) {
    this.defaultFormApi = DefaultFormApi(coreApi)

    this.pluginsApi = _(pluginAppStatesConfigs)
      .filter((config) => config.apiExtenderBuilder)
      .keyBy('name')
      .mapValues((config) => new config.apiExtenderBuilder(coreApi).getExtendApi())
      .value()
  }

  all() {
    return this.pluginsApi
  }

  withPlugins(plugins: ComponentPlugin[]) {
    const pluginIds = _.map(plugins, 'id')
    const { name: stateName } = getPluginAppState(pluginIds)
    return {
      supportApi: (apiPath) => this._supportApi(stateName, apiPath),
      callApi: (apiPath, ...payload) => this._callApi(stateName, apiPath, ...payload),
    }
  }

  async callDefaultApi(apiPath, ...payload) {
    const api = await _.invoke(this.defaultFormApi, apiPath, ...payload)
    return _.merge({}, api)
  }

  private _supportApi(pluginState, apiPath): boolean {
    return !!_.get(this.pluginsApi[pluginState], apiPath)
  }

  private async _callApi(pluginState, apiPath, ...payload) {
    if (!this._supportApi(pluginState, apiPath)) {
      throw new Error(`${pluginState}.${apiPath} not implemented`)
    }

    const defaultApi = await _.invoke(this.defaultFormApi, apiPath, ...payload)
    const api = await _.invoke(this.pluginsApi[pluginState], apiPath, ...payload)

    if (_.isPlainObject(defaultApi)) {
      return _.merge({}, defaultApi, api)
    }

    return api
  }
}
